const theme = {
  fonts: {
    cairo: 'Cairo, sans-serif',
    montserrat: 'Montserrat, sans-serif'
  },
  colors: {
    red: '#df1f26',
    grey: '#9B9B9B',
    grey2: '#707070'
  }
};

export default theme;
