import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import React, { useState } from 'react';
import { GeneralHeading, WhyUs } from '../styles/ar';

const WhyUsComponent = ({ data }) => {
  const [boxIsOpen, setBoxIsOpen] = useState(false);

  const showFullHeight = box => {
    if (boxIsOpen === false) {
      document.getElementById(box).classList.add('why-box-open');
      setBoxIsOpen(true);
    } else {
      document.getElementById(box).classList.remove('why-box-open');
      setBoxIsOpen(false);
    }
  };

  const onMouseLeave = box => {
    const boxDiv = document.getElementById(box);

    if (boxDiv.classList.contains('why-box-open')) {
      setTimeout(() => {
        boxDiv.classList.remove('why-box-open');
      }, 5000);
    }
  };

  return (
    <>
      <GeneralHeading>
        <h2>
          {data.HomepageContent.whyUsHeading}{' '}
          <span>{data.HomepageContent.whyUsHeadingBottom}</span>
        </h2>
      </GeneralHeading>
      <WhyUs>
        <div
          className="why-box"
          onMouseLeave={() => onMouseLeave('why-box-one')}
        >
          <img
            src={data.contentfulAboutUs.whyUsBoxOneIcon.file.url}
            alt="team icon"
          />
          <h2>{data.contentfulAboutUs.whyUsHeadingOne}</h2>
          <div className="content" id="why-box-one">
            {documentToReactComponents(data.contentfulAboutUs.whyUsBoxOne.json)}
          </div>
          <button onClick={() => showFullHeight('why-box-one')}>
            <span className="icon-chevrons-down"></span>
          </button>
        </div>
        <div
          className="why-box"
          onMouseLeave={() => onMouseLeave('why-box-two')}
        >
          <img
            src={data.contentfulAboutUs.whyUsBoxTwoIcon.file.url}
            alt="support icon"
          />
          <h2>{data.contentfulAboutUs.whyUsHeadingTwo}</h2>
          <div className="content" id="why-box-two">
            {documentToReactComponents(data.contentfulAboutUs.whyUsBoxTwo.json)}
          </div>
          <button onClick={() => showFullHeight('why-box-two')}>
            <span className="icon-chevrons-down"></span>
          </button>
        </div>
        <div
          className="why-box"
          onMouseLeave={() => onMouseLeave('why-box-three')}
        >
          <img
            src={data.contentfulAboutUs.whyUsBoxThreeIcon.file.url}
            alt="quality icon"
          />
          <h2>{data.contentfulAboutUs.whyUsHeadingThree}</h2>
          <div className="content" id="why-box-three">
            {documentToReactComponents(
              data.contentfulAboutUs.whyUsBoxThree.json
            )}
          </div>
          <button onClick={() => showFullHeight('why-box-three')}>
            <span className="icon-chevrons-down"></span>
          </button>
        </div>
        <div
          className="why-box"
          onMouseLeave={() => onMouseLeave('why-box-four')}
        >
          <img
            src={data.contentfulAboutUs.whyUsBoxFourIcon.file.url}
            alt="technologies icon"
          />
          <h2>{data.contentfulAboutUs.whyUsHeadingFour}</h2>
          <div className="content" id="why-box-four">
            {documentToReactComponents(
              data.contentfulAboutUs.whyUsBoxFour.json
            )}
          </div>
          <button onClick={() => showFullHeight('why-box-four')}>
            <span className="icon-chevrons-down"></span>
          </button>
        </div>
      </WhyUs>
    </>
  );
};

export default WhyUsComponent;
