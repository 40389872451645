import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import React, { useState } from 'react';
import styled from 'styled-components';
import Layout from '../components/layout';
import SEO from '../components/seo';

const RequestOffer = ({ data }) => {
  const StyledRequestOffer = styled.div`
    font-family: ${props => props.theme.fonts.montserrat};
    margin-bottom: 10rem;
    .hero {
      height: 20vh;
      .gatsby-image-wrapper {
        height: 100%;
      }
    }
    .content {
      width: 85%;
      padding: 2rem 0;
      margin: 5rem auto 0;
      p {
        font-size: 1.5rem;
        line-height: 4rem;
        color: #333333;
      }
      .payment-methods {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 6rem;
        .method {
          width: 22%;
          margin-bottom: 2rem;
          margin-right: 2rem;
          .gatsby-image-wrapper {
            box-shadow: 2px 5px 10px #d6d6d6;
          }
        }
      }
    }
    .title {
      margin-top: 5rem;
      z-index: 2;
      position: relative;
      font-size: 2.2rem;
      text-align: center;
      color: ${props => props.theme.colors.red};
    }
    .date {
      font-size: 1.8rem;
      display: inline-block;
      margin-bottom: 3rem;
    }
    form {
      width: 60%;
      margin: 5rem auto;
      input,
      option,
      textarea {
        width: 97%;
        margin: 2rem auto;
        display: block;
        padding: 2rem 1rem;
        font-size: 1.6rem;
      }
      button {
        background: ${props => props.theme.colors.red};
        border: 0;
        font-size: 1.6rem;
        color: white;
        cursor: pointer;
        padding: 2rem 4rem;
        &:hover {
          background: #333333;
        }
      }
      span {
        display: block;
        font-size: 1.8rem;
        margin-top: 2rem;
      }
    }
    @media (max-width: 768px) {
      .content {
        width: 80%;
        p {
          line-height: 2.5rem;
        }
        .payment-methods .method {
          width: 29.5%;
          margin-right: 1rem;
          margin-left: 1rem;
        }
      }
    }
    @media (max-width: 450px) {
      .content .payment-methods .method {
        width: 43%;
      }
    }
  `;

  const [buttonMessage, setButtonMessage] = useState('Send Offer');
  const [showMessage, setShowMessage] = useState(false);

  const onSubmit = async e => {
    e.preventDefault();

    setButtonMessage('Sending...');

    const data = new FormData(e.target);

    const url =
      'https://script.google.com/macros/s/AKfycbztEslN-NjW7vvSFxlYpRc961PiruBXw7ACBM85/exec';

    const res = await fetch(url, {
      method: 'POST',
      body: data
    });

    if (res.status === 200) {
      setShowMessage(true);
      setButtonMessage('Send Offer');
    }
  };

  return (
    <Layout>
      <SEO title="Request Offer" />
      <StyledRequestOffer>
        <div className="hero">
          <Img fluid={data.contentfulAboutUs.requestOfferImage.fluid} />
        </div>
        <div className="title">
          <h1>Request Offer</h1>
        </div>
        <form onSubmit={onSubmit}>
          <input type="text" name="name" placeholder="Name" />
          <input type="email" name="email" placeholder="Email" />
          <input type="text" name="company" placeholder="Company" />
          <input type="text" name="country" placeholder="Country" />
          <input type="text" name="city" placeholder="City" />
          <input type="text" name="phone" placeholder="Phone Number" />
          <br />
          <select>
            {data.allContentfulCategories.edges.map(category => (
              <option
                key={category.node.slug}
                value={category.node.englishName}
              >
                {category.node.englishName}
              </option>
            ))}
          </select>
          <textarea name="details" placeholder="Details" rows="20"></textarea>
          <button type="submit">{buttonMessage}</button>
          {showMessage && (
            <span>Offer sent! we'll reply as soon as possible.</span>
          )}
        </form>
      </StyledRequestOffer>
    </Layout>
  );
};

export const data = graphql`
  {
    allContentfulCategories(sort: { fields: [createdAt], order: DESC }) {
      edges {
        node {
          englishName
          slug
        }
      }
    }
    contentfulAboutUs {
      requestOfferImage {
        fluid(maxWidth: 1920) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
  }
`;

export default RequestOffer;
