import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { GeneralHeading, StyledOurPartners } from '../styles/ar/index';

const OurPartnersComponent = ({ headings, dataHomepage }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 8,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }
    ]
  };

  return (
    <StaticQuery
      query={graphql`
        {
          allContentfulPartners(
            limit: 10
            sort: { fields: [createdAt], order: DESC }
          ) {
            edges {
              node {
                partnerName
                logo {
                  fluid(maxWidth: 250) {
                    ...GatsbyContentfulFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
        }
      `}
      render={data => (
        <>
          <GeneralHeading>
            <h2>
              {headings.homepagePartners}{' '}
              <span>{headings.homepagePartnersBottom}</span>
            </h2>
          </GeneralHeading>
          <StyledOurPartners>
            <div className="container">
              <div className="content">
                <Slider {...settings}>
                  {data.allContentfulPartners.edges.map(partner => {
                    return (
                      <div className="item" key={partner.node.partnerName}>
                        <Img fluid={partner.node.logo.fluid} />
                      </div>
                    );
                  })}
                </Slider>
              </div>
            </div>
          </StyledOurPartners>
        </>
      )}
    />
  );
};

export default OurPartnersComponent;
