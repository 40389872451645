import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import React, { useState } from 'react';
import styled from 'styled-components';
import Layout from '../components/layout';
import SEO from '../components/seo';

const OurClients = ({ data }) => {
  const firstPage = data.allContentfulPartners.edges.slice(0, 8);

  const [clients, setClients] = useState(firstPage);
  const [allLoaded, setAllLoaded] = useState(false);

  const onClick = () => {
    let clientsRendered = clients;

    if (clients.length + 8 > data.allContentfulPartners.edges.length) {
      setAllLoaded(true);
    } else {
      clientsRendered = data.allContentfulPartners.edges.slice(
        0,
        clients.length + 8
      );
    }

    setClients(clientsRendered);
  };

  const StyledOurClients = styled.div`
    font-family: ${props => props.theme.fonts.montserrat};
    margin-bottom: 10rem;
    .hero {
      height: 20vh;
      .gatsby-image-wrapper {
        height: 100%;
      }
    }
    .content {
      width: 85%;
      padding: 2rem 0;
      margin: 5rem auto 0;
      p {
        font-size: 1.5rem;
        line-height: 4rem;
        text-align: center;
        color: #333333;
      }
      .payment-methods {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 6rem;
        .method {
          width: 22%;
          margin-bottom: 2rem;
          margin-right: 2rem;
          .gatsby-image-wrapper {
            box-shadow: 2px 5px 10px #d6d6d6;
            height: 20rem;
          }
        }
      }
    }
    .load-more {
      text-align: center;
      margin: 2rem 0 5rem;
      button {
        background: transparent;
        border-radius: 3rem;
        border: 2px solid ${props => props.theme.colors.red};
        outline: 0;
        padding: 1.8rem 4rem;
        cursor: pointer;
        transition: 0.4s all;
        font-size: 1.5rem;
        &:hover {
          background: ${props => props.theme.colors.red};
          color: #ffffff;
        }
      }
    }
    .title {
      margin-top: 3rem;
      z-index: 2;
      position: relative;
      text-align: center;
      font-size: 2.5rem;
      color: ${props => props.theme.colors.red};
    }
    .date {
      font-size: 1.8rem;
      display: inline-block;
      margin-bottom: 3rem;
    }
    @media (max-width: 768px) {
      .content {
        width: 80%;
        p {
          line-height: 2.5rem;
        }
        .payment-methods .method {
          width: 45%;
          margin-right: 1rem;
          margin-left: 1rem;
        }
      }
    }
    @media (max-width: 450px) {
      .content .payment-methods .method {
        width: 90%;
      }
    }
  `;

  const featuredClients = data.contentfulOurPartners.featuredClients;

  const filtered = featuredClients.map(client => client.partnerName);

  const renderClients = partners => {
    const filteredPartners = partners.filter(
      partner => !filtered.includes(partner.node.partnerName)
    );

    return filteredPartners.map(partner => (
      <div key={partner.node.partnerName} className="method">
        <Img fluid={partner.node.logo.fluid} />
        <p>{partner.node.partnerName}</p>
      </div>
    ));
  };

  return (
    <Layout>
      <SEO title="Clients" />
      <StyledOurClients>
        <div className="hero">
          <Img fluid={data.contentfulOurPartners.allClientsBackground.fluid} />
        </div>
        <div className="content">
          <h1 className="title">
            {data.contentfulOurPartners.ourPartnersHeadingEn}
          </h1>
          <div className="payment-methods">
            {featuredClients.map(partner => (
              <div key={partner.partnerName} className="method">
                <Img fluid={partner.logo.fluid} />
                <p>{partner.partnerName}</p>
              </div>
            ))}
            {renderClients(clients)}
          </div>
          <div className="load-more">
            {!allLoaded && <button onClick={onClick}>Show More Clients</button>}
          </div>
        </div>
      </StyledOurClients>
    </Layout>
  );
};

export const data = graphql`
  {
    allContentfulPartners(sort: { fields: [createdAt], order: DESC }) {
      edges {
        node {
          partnerName
          logo {
            fluid(maxWidth: 600) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
      }
    }
    contentfulOurPartners {
      ourPartnersHeadingEn
      allClientsBackground {
        fluid(maxWidth: 1920) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      featuredClients {
        partnerName
        logo {
          fluid(maxWidth: 600) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
    }
  }
`;

export default OurClients;
