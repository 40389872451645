import { graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import React from 'react';
import styled from 'styled-components';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { Link } from '../components/LinkWithBack';

const Projects = ({ data, location }) => {
  const StyledPosts = styled.div`
    width: 90%;
    margin: 10rem auto;
    .heading {
      font-size: 1.6rem;
      font-family: ${props => props.theme.fonts.montserrat};
      span {
        display: block;
        color: ${props => props.theme.colors.red};
        font-size: 4rem;
        font-weight: bold;
      }
    }
    .posts {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      list-style-type: none;
      margin: 5rem 0;
      .post {
        width: 32.5%;
        background: #333;
        margin-top: 2rem;
        height: 30rem;
        position: relative;
        margin: 1.5rem 0.5rem;
        .bg {
          height: 100%;
          h2 {
            margin: 0;
            position: absolute;
            bottom: 3rem;
            left: 0;
            padding: 1.5rem 3rem;
            background: #ffffff;
            font-family: ${props => props.theme.fonts.montserrat};
            max-width: 75%;
            color: #000000;
            transition: 0.3s color;
            &:hover {
              color: ${props => props.theme.colors.red};
            }
            &:visited {
              color: #000000;
            }
          }
        }
        &:nth-child(1),
        &:nth-child(2) {
          width: 49%;
          height: 40rem;
        }
      }
    }
    @media (max-width: 1024px) {
      .posts .post {
        margin: 1.5rem 0.3rem;
        height: 20rem;
        &:nth-child(1),
        &:nth-child(2) {
          height: 30rem;
        }
      }
    }

    @media (max-width: 768px) {
      .posts {
        justify-content: flex-start;
      }
      .posts .post {
        width: 49%;
      }
    }

    @media (max-width: 414px) {
      .posts .post {
        width: 100%;
        height: 20rem;
        &:nth-child(1),
        &:nth-child(2) {
          width: 100%;
          height: 25rem;
        }
      }
    }
  `;

  return (
    <Layout>
      <SEO title="Blog" />
      <StyledPosts>
        <div className="heading">
          <h2>
            {data.contentfulBlog.blogHeadingEn}{' '}
            <span>{data.contentfulBlog.blogHeadingBottomEn}</span>
          </h2>
        </div>
        <ul className="posts">
          {data.allPosts.edges.map(post => (
            <li id={post.node.slug} key={post.node.slug} className="post">
              <Link
                state={{ id: post.node.slug }}
                to={'/blog/' + post.node.slug}
              >
                <BackgroundImage
                  Tag="div"
                  fluid={post.node.hero.fluid}
                  className="bg"
                  backgroundColor={`#040e18`}
                >
                  <h2>{post.node.englishTitle}</h2>
                </BackgroundImage>
              </Link>
            </li>
          ))}
        </ul>
      </StyledPosts>
    </Layout>
  );
};

export const data = graphql`
  {
    allPosts: allContentfulPosts(sort: { fields: createdAt, order: DESC }) {
      edges {
        node {
          englishTitle
          hero {
            fluid(maxWidth: 1920) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          slug
        }
      }
    }
    contentfulBlog {
      blogHeadingEn
      blogHeadingBottomEn
    }
  }
`;

export default Projects;
