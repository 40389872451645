import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { graphql, StaticQuery } from 'gatsby';
import React, { useState } from 'react';
import { StyledKnowMore } from './styles';

const KnowMore = () => {
  const [hasVideo, setHasVideo] = useState(false);
  const renderAssets = asset => {
    if (asset.file.contentType.includes('video')) {
      setHasVideo(true);
      return (
        <video autoPlay muted loop controls>
          <source src={asset.file.url} />
        </video>
      );
    } else {
      return <img src={asset.file.url} alt="know more" />;
    }
  };

  return (
    <StaticQuery
      query={graphql`
        {
          HomepageContent: contentfulAboutUs {
            knowMoreImage {
              file {
                url
                contentType
              }
            }
            knowMoreHeadingEn
            knowMoreHeadingBottomEn
            knowMoreBoxOneIcon {
              file {
                url
              }
            }
            knowMoreBoxTwoIcon {
              file {
                url
              }
            }
            knowMoreBoxThreeIcon {
              file {
                url
              }
            }
            knowMoreHeadingOneEn
            knowMoreHeadingTwoEn
            knowMoreHeadingThreeEn
            knowMoreBoxOneEn {
              json
            }
            knowMoreBoxTwoEn {
              json
            }
            knowMoreBoxThreeEn {
              json
            }
          }
        }
      `}
      render={data => (
        <StyledKnowMore>
          <div className="heading">
            <h2>
              {data.HomepageContent.knowMoreHeadingEn}{' '}
              <span>{data.HomepageContent.knowMoreHeadingBottomEn}</span>
            </h2>
          </div>
          <div className={`service ${hasVideo && 'has-video'}`}>
            <div className="service-photo">
              {renderAssets(data.HomepageContent.knowMoreImage)}
            </div>
            <div className="service-details">
              <div className="outline">
                <div className="content">
                  <div className="box">
                    <div className="more-heading">
                      <img
                        src={data.HomepageContent.knowMoreBoxOneIcon.file.url}
                        alt="vision icon"
                      />
                      <h2>{data.HomepageContent.knowMoreHeadingOneEn}</h2>
                    </div>
                    <div className="more-content">
                      {documentToReactComponents(
                        data.HomepageContent.knowMoreBoxOneEn.json
                      )}
                    </div>
                  </div>
                  <div className="box">
                    <div className="more-heading">
                      <img
                        src={data.HomepageContent.knowMoreBoxTwoIcon.file.url}
                        alt="vision icon"
                      />
                      <h2>{data.HomepageContent.knowMoreHeadingTwoEn}</h2>
                    </div>
                    <div className="more-content">
                      {documentToReactComponents(
                        data.HomepageContent.knowMoreBoxTwoEn.json
                      )}
                    </div>
                  </div>
                  <div className="box">
                    <div className="more-heading">
                      <img
                        src={data.HomepageContent.knowMoreBoxThreeIcon.file.url}
                        alt="vision icon"
                      />
                      <h2>{data.HomepageContent.knowMoreHeadingThreeEn}</h2>
                    </div>
                    <div className="more-content">
                      {documentToReactComponents(
                        data.HomepageContent.knowMoreBoxThreeEn.json
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </StyledKnowMore>
      )}
    />
  );
};

export default KnowMore;
