// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-static-js": () => import("/opt/build/repo/src/templates/static.js" /* webpackChunkName: "component---src-templates-static-js" */),
  "component---src-templates-static-en-js": () => import("/opt/build/repo/src/templates/static-en.js" /* webpackChunkName: "component---src-templates-static-en-js" */),
  "component---src-templates-post-js": () => import("/opt/build/repo/src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-post-en-js": () => import("/opt/build/repo/src/templates/post-en.js" /* webpackChunkName: "component---src-templates-post-en-js" */),
  "component---src-templates-category-js": () => import("/opt/build/repo/src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-projects-js": () => import("/opt/build/repo/src/templates/projects.js" /* webpackChunkName: "component---src-templates-projects-js" */),
  "component---src-templates-category-en-js": () => import("/opt/build/repo/src/templates/category-en.js" /* webpackChunkName: "component---src-templates-category-en-js" */),
  "component---src-templates-projects-en-js": () => import("/opt/build/repo/src/templates/projects-en.js" /* webpackChunkName: "component---src-templates-projects-en-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/opt/build/repo/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-ar-404-js": () => import("/opt/build/repo/src/pages/ar/404.js" /* webpackChunkName: "component---src-pages-ar-404-js" */),
  "component---src-pages-ar-about-js": () => import("/opt/build/repo/src/pages/ar/about.js" /* webpackChunkName: "component---src-pages-ar-about-js" */),
  "component---src-pages-ar-blog-js": () => import("/opt/build/repo/src/pages/ar/blog.js" /* webpackChunkName: "component---src-pages-ar-blog-js" */),
  "component---src-pages-ar-clients-js": () => import("/opt/build/repo/src/pages/ar/clients.js" /* webpackChunkName: "component---src-pages-ar-clients-js" */),
  "component---src-pages-ar-contact-js": () => import("/opt/build/repo/src/pages/ar/contact.js" /* webpackChunkName: "component---src-pages-ar-contact-js" */),
  "component---src-pages-ar-index-js": () => import("/opt/build/repo/src/pages/ar/index.js" /* webpackChunkName: "component---src-pages-ar-index-js" */),
  "component---src-pages-ar-payment-methods-js": () => import("/opt/build/repo/src/pages/ar/payment-methods.js" /* webpackChunkName: "component---src-pages-ar-payment-methods-js" */),
  "component---src-pages-ar-projects-js": () => import("/opt/build/repo/src/pages/ar/projects.js" /* webpackChunkName: "component---src-pages-ar-projects-js" */),
  "component---src-pages-ar-request-offer-js": () => import("/opt/build/repo/src/pages/ar/request-offer.js" /* webpackChunkName: "component---src-pages-ar-request-offer-js" */),
  "component---src-pages-ar-services-js": () => import("/opt/build/repo/src/pages/ar/services.js" /* webpackChunkName: "component---src-pages-ar-services-js" */),
  "component---src-pages-blog-js": () => import("/opt/build/repo/src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-clients-js": () => import("/opt/build/repo/src/pages/clients.js" /* webpackChunkName: "component---src-pages-clients-js" */),
  "component---src-pages-contact-js": () => import("/opt/build/repo/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-payment-methods-js": () => import("/opt/build/repo/src/pages/payment-methods.js" /* webpackChunkName: "component---src-pages-payment-methods-js" */),
  "component---src-pages-projects-js": () => import("/opt/build/repo/src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-request-offer-js": () => import("/opt/build/repo/src/pages/request-offer.js" /* webpackChunkName: "component---src-pages-request-offer-js" */),
  "component---src-pages-services-js": () => import("/opt/build/repo/src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */)
}

