import { graphql, StaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import React, { useState } from 'react';
import styled from 'styled-components';
import Logo from '../images/logo-2.svg';
import LogoWhiteBG from '../images/logo-white-bg.svg';
import { Link } from './LinkWithBack';

const Footer = () => {
  const [buttonMessage, setButtonMessage] = useState('Send');
  const [showMessage, setShowMessage] = useState(false);

  const StyledFooter = styled.footer`
    background: #000000;
    font-family: ${props => props.theme.fonts.montserrat};
    color: white;
    .footer {
      width: 80%;
      margin: auto;
      display: flex;
      .pages {
        width: 15%;
      }
      .info,
      .categories {
        width: 25%;
      }
      .form {
        width: 35%;
      }
      .bar {
        padding: 6rem 0;
        margin: 0 1rem;
        h2 {
          font-size: 2.2rem;
          margin: 0 0 2.5rem;
          color: ${props => props.theme.colors.red};
        }
        p {
          font-size: 1.5rem;
          color: #c1c1c1;
        }
        ul {
          list-style: none;
          li {
            margin-bottom: 1.5rem;
            a {
              text-decoration: none;
              font-size: 1.4rem;
              color: #ffffff;
              &:hover {
                color: ${props => props.theme.colors.red};
              }
            }
          }
        }
        form {
          input {
            width: 87%;
            float: left;
            background: rgba(0, 0, 0, 0.8);
            border: 2px solid#ffffff;
            padding: 2rem;
            margin-bottom: 1.5rem;
            color: #ffffff;
            font-size: 1.6rem;
          }
          span {
            margin-bottom: 1.5rem;
            font-size: 1.3rem;
            display: block;
          }
          button {
            background: #000000;
            border: 3px solid#222222;
            color: #ffffff;
            font-size: 1.8rem;
            padding: 1.5rem 3rem;
            cursor: pointer;
            &:hover {
              background: ${props => props.theme.colors.red};
              border-color: ${props => props.theme.colors.red};
            }
          }
        }
        .logo {
          width: 75%;
          display: inline-block;
          margin-bottom: 2rem;
        }
        .icons {
          display: flex;
          flex-direction: column;
          margin-top: 3rem;
          img {
            width: 65%;
            margin-bottom: 1rem;
          }
        }
      }
    }
    .footer-bottom {
      background: #000000;
      padding: 1.5rem 0;
      font-size: 1.5rem;
      .content {
        width: 75%;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        p {
          display: flex;
          align-items: center;
          img {
            width: 25px;
            margin: 0 0.5rem;
          }
        }

        .social-media a {
          padding: 1rem;
          margin-right: 1rem;
          text-decoration: none;
          &:hover {
            background: ${props => props.theme.colors.red};
          }
        }
      }
    }
    @media (min-width: 2560px) {
      .footer {
        max-width: 65%;
        margin: auto;
      }
    }
    @media (min-width: 1920px) and (max-width: 2550px) {
      .footer {
        width: 80%;
        margin: auto;
      }
    }
    @media (max-width: 768px) {
      .footer {
        flex-wrap: wrap;
        flex-direction: column-reverse;
        .bar {
          width: 100%;
          text-align: right;
          img {
            width: 25%;
          }
        }
      }
    }
    @media (max-width: 450px) {
      .footer {
        .bar {
          text-align: right;
          img {
            width: 50%;
          }
        }
      }
      .footer-bottom {
        padding: 1.6rem 0;
        .content {
          flex-direction: column;
          width: 100%;
          .social-media {
            margin: 1rem 0;
          }
        }
      }
    }
  `;

  const onSubmit = async e => {
    e.preventDefault();

    setButtonMessage('Sending..');

    const data = new FormData(e.target);

    const url =
      'https://script.google.com/macros/s/AKfycbztEslN-NjW7vvSFxlYpRc961PiruBXw7ACBM85/exec';

    const res = await fetch(url, {
      method: 'POST',
      body: data
    });

    if (res.status === 200) {
      setShowMessage(true);
      setButtonMessage('Send');
    }
  };

  return (
    <StaticQuery
      query={graphql`
        {
          contentfulContactDetailsEnglish {
            egyptAddress
            email
            phone
          }
          contentfulSocialMediaInfo {
            facebookLink
            twitterLink
            instagramLink
            youtubeLink
            behanceLink
            linkedInLink
          }
          contentfulFooter {
            firstColumnHeadingEn
            playStoreUrl
            googlePlayUrl
            playStoreImg {
              file {
                url
              }
            }
            googlePlayImg {
              file {
                url
              }
            }
            footerImg {
              fluid(maxWidth: 1920) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
            columnOneContent {
              englishName
              slug
            }
            secondColumnHeadingEn
            secondColumnContent {
              englishTitle
              url
            }
          }
        }
      `}
      render={data => (
        <StyledFooter>
          <BackgroundImage
            Tag="div"
            fluid={data.contentfulFooter.footerImg.fluid}
            backgroundColor={'#000000'}
          >
            <div className="footer">
              <div className="bar info">
                <img className="logo" src={Logo} alt="logo icon" />
                <br />
                <p>
                  <span className="icon-chevron-right">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </span>
                  {data.contentfulContactDetailsEnglish.egyptAddress}
                </p>
                <p>
                  <span className="icon-chevron-right">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </span>
                  {data.contentfulContactDetailsEnglish.phone}
                </p>
                <p>
                  <span className="icon-chevron-right">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </span>
                  {data.contentfulContactDetailsEnglish.email}
                </p>
                <div className="icons">
                  <a
                    href={data.contentfulFooter.playStoreUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={data.contentfulFooter.playStoreImg.file.url}
                      alt="app store"
                    />
                  </a>
                  <a
                    href={data.contentfulFooter.googlePlayUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={data.contentfulFooter.googlePlayImg.file.url}
                      alt="google play"
                    />
                  </a>
                </div>
              </div>
              <div className="bar categories">
                <h2>{data.contentfulFooter.firstColumnHeadingEn}</h2>
                <ul>
                  {data.contentfulFooter.columnOneContent.map((item, i) => (
                    <li key={i}>
                      <Link to={`/projects/${item.slug}`}>
                        <span className="icon-chevron-right">
                          <span className="path1"></span>
                          <span className="path2"></span>
                        </span>
                        {item.englishName}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="bar pages">
                <h2>{data.contentfulFooter.secondColumnHeadingEn}</h2>
                <ul>
                  {data.contentfulFooter.secondColumnContent.map((item, i) => (
                    <li key={i}>
                      <Link to={`${item.url}`}>
                        <span className="icon-chevron-right">
                          <span className="path1"></span>
                          <span className="path2"></span>
                        </span>
                        {item.englishTitle}
                      </Link>
                    </li>
                  ))}
                  <li>
                    <a
                      href="/3box-brochure.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="icon-chevron-right">
                        <span className="path1"></span>
                        <span className="path2"></span>
                      </span>
                      Company's profile
                    </a>
                  </li>
                </ul>
              </div>
              <div className="bar form">
                <h2>Contact Us</h2>
                <form onSubmit={onSubmit}>
                  <input type="text" name="name" placeholder="Name" required />
                  <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    required
                  />
                  <input
                    type="text"
                    name="message"
                    placeholder="Message"
                    required
                  />
                  {showMessage && (
                    <span>
                      Your message was successfully sent, we'll reply soon.
                    </span>
                  )}
                  <button type="submit">{buttonMessage}</button>
                </form>
              </div>
            </div>
            <div className="footer-bottom">
              <div className="content">
                <p>
                  Copyright © 2019{' '}
                  <Link to="/">
                    <img src={LogoWhiteBG} alt="3box advertising" />
                  </Link>
                  . All Rights Reserved.
                </p>
                <div className="social-media">
                  <a
                    href={`${data.contentfulContactDetailsEnglish.facebookLink}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="icon-facebook">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </span>
                  </a>

                  <a
                    href={`${data.contentfulContactDetailsEnglish.twitterLink}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="icon-twitter">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </span>
                  </a>

                  <a
                    href={`${data.contentfulContactDetailsEnglish.instagramLink}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="icon-instagram">
                      <span className="path1"></span>
                      <span className="path2"></span>
                      <span className="path4"></span>
                    </span>
                  </a>

                  <a
                    href={`${data.contentfulContactDetailsEnglish.youtubeLink}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="icon-youtube">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </span>
                  </a>

                  <a
                    href={`${data.contentfulContactDetailsEnglish.behanceLink}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="icon-behance">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </span>
                  </a>

                  <a
                    href={`${data.contentfulContactDetailsEnglish.linkedInLink}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="icon-linkedin">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </BackgroundImage>
        </StyledFooter>
      )}
    />
  );
};

export default Footer;
