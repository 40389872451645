import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { graphql, StaticQuery } from 'gatsby';
import React, { useState } from 'react';
import { StyledKnowMore } from '../styles/ar';

const KnowMore = () => {
  const [hasVideo, setHasVideo] = useState(false);
  const renderAssets = asset => {
    if (asset.file.contentType.includes('video')) {
      setHasVideo(true);
      return (
        <video autoPlay muted loop controls>
          <source src={asset.file.url} />
        </video>
      );
    } else {
      return <img src={asset.file.url} alt="know more" />;
    }
  };

  return (
    <StaticQuery
      query={graphql`
        {
          HomepageContent: contentfulAboutUs {
            knowMoreImage {
              file {
                url
                contentType
              }
            }
            knowMoreHeading
            knowMoreHeadingBottom
            knowMoreBoxOneIcon {
              file {
                url
              }
            }
            knowMoreBoxTwoIcon {
              file {
                url
              }
            }
            knowMoreBoxThreeIcon {
              file {
                url
              }
            }
            knowMoreHeadingOne
            knowMoreHeadingTwo
            knowMoreHeadingThree
            knowMoreBoxOne {
              json
            }
            knowMoreBoxTwo {
              json
            }
            knowMoreBoxThree {
              json
            }
          }
        }
      `}
      render={data => (
        <StyledKnowMore>
          <div className="heading">
            <h2>
              {data.HomepageContent.knowMoreHeading}{' '}
              <span>{data.HomepageContent.knowMoreHeadingBottom}</span>
            </h2>
          </div>
          <div className={`service ${hasVideo && 'has-video'}`}>
            <div className="service-photo">
              {renderAssets(data.HomepageContent.knowMoreImage)}
            </div>
            <div className="service-details">
              <div className="outline">
                <div className="content">
                  <div className="box">
                    <div className="more-heading">
                      <h2>{data.HomepageContent.knowMoreHeadingOne}</h2>
                      <img
                        src={data.HomepageContent.knowMoreBoxOneIcon.file.url}
                        alt="vision icon"
                      />
                    </div>
                    <div className="more-content">
                      {documentToReactComponents(
                        data.HomepageContent.knowMoreBoxOne.json
                      )}
                    </div>
                  </div>
                  <div className="box">
                    <div className="more-heading">
                      <h2>{data.HomepageContent.knowMoreHeadingTwo}</h2>
                      <img
                        src={data.HomepageContent.knowMoreBoxTwoIcon.file.url}
                        alt="vision icon"
                      />
                    </div>
                    <div className="more-content">
                      {documentToReactComponents(
                        data.HomepageContent.knowMoreBoxTwo.json
                      )}
                    </div>
                  </div>
                  <div className="box">
                    <div className="more-heading">
                      <h2>{data.HomepageContent.knowMoreHeadingThree}</h2>
                      <img
                        src={data.HomepageContent.knowMoreBoxThreeIcon.file.url}
                        alt="vision icon"
                      />
                    </div>
                    <div className="more-content">
                      {documentToReactComponents(
                        data.HomepageContent.knowMoreBoxThree.json
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </StyledKnowMore>
      )}
    />
  );
};

export default KnowMore;
