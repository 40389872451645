import Img from 'gatsby-image';
import React from 'react';

const OurPartners = ({ featuredPartners }) => {
  return (
    <>
      {featuredPartners.map(partner => (
        <div key={partner.node.partnerName} className="partner">
          <Img fluid={partner.node.logo.fluid} />
        </div>
      ))}
    </>
  );
};

export default OurPartners;
