import { motion } from 'framer-motion';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import React, { useEffect, useState } from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { Link } from '../components/LinkWithBack';
import {
  ControlButtons,
  ProjectsFilters,
  ProjectsHeader,
  StyledProjects
} from '../components/styles/projects';
import ArrowBackIcon from '../images/icons/arrow-back.svg';
import GoHomeIcon from '../images/icons/go-home.svg';

const Projects = ({ data, location }) => {
  const [currentURL, setCurrentURL] = useState('');

  const firstPage = data.allProjects.edges.slice(0, 9);

  const [projects, setProjects] = useState(firstPage);
  const [allLoaded, setAllLoaded] = useState(false);

  const onClick = () => {
    let projectsRendered = projects;

    if (projects.length + 6 > data.allProjects.edges.length) {
      setAllLoaded(true);
    } else {
      projectsRendered = data.allProjects.edges.slice(0, projects.length + 6);
    }

    setProjects(projectsRendered);
  };

  useEffect(() => {
    if (typeof window == 'undefined') {
      return setCurrentURL(location.pathname);
    }
  }, [location.pathname]);

  const renderBack = () => {
    if (typeof window !== 'undefined') {
      if (location.state && location.state.prevUrl) {
        return (
          <Link state={currentURL} to={location.state.prevUrl}>
            <img src={ArrowBackIcon} alt="go back" />
          </Link>
        );
      }
    }
  };

  return (
    <Layout>
      <SEO title="Projects" />
      <ProjectsHeader>
        <motion.div
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.5 }}
          className="photo"
        >
          <Img
            fluid={
              data.contentfulPreviousWorkCategories.allProjectsBackground.fluid
            }
          />
        </motion.div>
        <div className="header-content">
          <h1>{data.contentfulPreviousWorkCategories.allProjectsHeadingEn}</h1>
        </div>
      </ProjectsHeader>
      <ControlButtons>
        {renderBack()}
        <Link state={currentURL} to="/">
          <img src={GoHomeIcon} alt="go home" />
        </Link>
      </ControlButtons>
      <StyledProjects>
        <ProjectsFilters>
          <li>
            <Link
              state={currentURL}
              to="/projects"
              activeClassName="current-type"
            >
              All Categories
            </Link>
          </li>
          {data.allCategories.edges.map(category => (
            <li key={category.node.slug}>
              <Link to={`/projects/${category.node.slug}`}>
                {category.node.englishName}
              </Link>
            </li>
          ))}
        </ProjectsFilters>
        <div className="left">
          <div className="projects">
            {projects.map(project => (
              <div key={project.node.englishTitle} className="project">
                <a
                  href={project.node.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Img fluid={project.node.hero.fluid} />
                </a>
                <a
                  href={project.node.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <h3>{project.node.englishTitle}</h3>
                </a>

                <p>{project.node.category.englishName}</p>
              </div>
            ))}
          </div>
          <div className="load-more">
            {!allLoaded && (
              <button onClick={onClick}>Show More Projects</button>
            )}
          </div>
          <div className="go-to-behance">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={data.contentfulSocialMediaInfo.behanceLink}
            >
              <span className="icon-behance">
                <span className="path1"></span>
                <span className="path2"></span>
              </span>
              Visit our Behance
            </a>
          </div>
        </div>
      </StyledProjects>
    </Layout>
  );
};

export const data = graphql`
  {
    allCategories: allContentfulCategories(
      sort: { fields: createdAt, order: ASC }
    ) {
      edges {
        node {
          englishName
          slug
        }
      }
    }
    allProjects: allContentfulProjects(
      sort: { fields: createdAt, order: DESC }
    ) {
      edges {
        node {
          englishTitle
          category {
            englishName
          }
          hero {
            fluid(maxWidth: 1920) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          url
          slug
        }
      }
    }
    contentfulPreviousWorkCategories {
      allProjectsHeadingEn
      allProjectsBackground {
        fluid(maxWidth: 1920) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
    contentfulSocialMediaInfo {
      behanceLink
    }
  }
`;

export default Projects;
