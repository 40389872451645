import { Location } from '@reach/router';
import { Link } from 'gatsby';
import React from 'react';

const LinkWithPrevUrl = ({ children, state, ...rest }) => (
  <Location>
    {({ location }) => (
      <Link {...rest} state={{ prevUrl: location.pathname, ...state }}>
        {children}
      </Link>
    )}
  </Location>
);

export { LinkWithPrevUrl as Link };
