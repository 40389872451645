import { motion } from 'framer-motion';
import { graphql, StaticQuery } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import CloseMenu from '../../images/icons/close-menu.svg';
import MobileMenu from '../../images/icons/mobile-menu.svg';
import Logo from '../../images/logo.svg';
import { Link } from './LinkWithBack';

const Menu = () => {
  const closeMenu = () => {
    document
      .getElementById('styled-mobile-menu')
      .classList.remove('menu-is-open');
  };

  const openMenu = () => {
    document.getElementById('styled-mobile-menu').classList.add('menu-is-open');
  };

  const StyleTopHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 5rem;
    .change-language {
      font-size: 1.6rem;
      font-family: ${props => props.theme.fonts.cairo};
      display: flex;
      a {
        display: flex;
        align-items: center;
        margin-right: 1rem;
        text-decoration: none;
        padding: 0.8rem;
        border: 1px solid ${props => props.theme.colors.red};
        border-radius: 50%;
        &:hover {
          background: ${props => props.theme.colors.red};
          span {
            color: #ffffff;
          }
        }
        span {
          font-size: 1.2rem;
          color: ${props => props.theme.colors.red};
        }
      }
    }
    .inline-icons {
      display: flex;
      font-family: ${props => props.theme.fonts.cairo};
    }
    .inline-icon {
      display: flex;
      align-items: center;
      margin-left: 2rem;
      span {
        font-size: 2.8rem;
        margin-right: 1rem;
      }
      p {
        font-size: 1.5rem;
        a {
          color: #333333;
          text-decoration: none;
        }
      }
    }
    @media (min-width: 2560px) {
      max-width: 65%;
      margin: auto;
    }
    @media (min-width: 1920px) and (max-width: 2550px) {
      width: 80%;
      margin: auto;
    }
    @media (max-width: 450px) {
      .change-language {
        position: fixed;
        bottom: 5rem;
        left: 2rem;
        z-index: 5;
        flex-direction: column;
        a {
          margin-bottom: 2rem;
          img {
            width: 4rem;
          }
        }
      }
      .inline-icons {
        justify-content: center;
        width: 100%;
        .inline-icon {
          margin: 0 1rem;
        }
      }
    }
    @media (max-width: 360px) {
      .inline-icons {
        width: 100%;
        margin: 0;
        justify-content: center;
        .inline-icon {
          margin-left: 1rem;
          p {
            font-size: 1.3rem;
          }
        }
      }
    }
  `;

  const StyledHeader = styled.header`
    border-top: 2px solid#e6e6e6;
    border-bottom: 2px solid#e6e6e6;
    position: relative;
    .container {
      display: flex;
      justify-content: flex-end;
      .social-media {
        width: 30%;
        display: flex;
        align-items: center;
        list-style-type: none;
        margin-left: 5rem;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        .social-top {
          margin-right: 1.5rem;
          text-decoration: none;
          background: ${props => props.theme.colors.red};
          padding: 2rem 1rem;
          border-radius: 0 0 2rem 2rem;
          transition: 0.5s all;
          &:hover {
            background: #333333;
          }
          span {
            font-size: 2rem;
            &:hover {
              .path1:before {
                color: #333;
              }
            }
          }
        }
      }
      .mob-menu {
        display: none;
      }
      @media (min-width: 2560px) {
        max-width: 65%;
        margin: auto;
        box-shadow: 1px 3px 2px #d6d6d6;
        .social-media {
          left: 15.5%;
        }
      }
      @media (min-width: 1920px) and (max-width: 2550px) {
        width: 80%;
        margin: auto;
        box-shadow: 1px 3px 2px #d6d6d6;
        .social-media {
          left: 7.5%;
        }
      }
      .menu {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin: 1.5rem 5rem;
        width: 70%;
        .logo {
          width: 15%;
        }
        @media (min-width: 1920px) {
          .logo {
            width: 12%;
          }
        }
      }
    }
    @media (max-width: 1024px) {
      .container {
        flex-wrap: wrap;
        .social-media {
          width: 100%;
          top: 8.5rem;
          margin-left: 0;
          justify-content: center;
        }
        .menu {
          width: 100%;
          justify-content: center;
          ul {
            margin-right: 3rem;
          }
        }
      }
    }
    @media (max-width: 768px) {
      .container {
        .social-media {
          top: 9.8rem;
        }
        .menu {
          flex-direction: column-reverse;
          .logo {
            margin-bottom: 1rem;
          }
        }
      }
    }
    @media (max-width: 450px) {
      .container {
        flex-wrap: nowrap;
        .menu {
          margin: 2rem 0;
          ul {
            display: none;
          }
          .logo {
            width: 50%;
          }
        }
        .social-media {
          top: 10rem;
        }
        .mob-menu {
          display: block;
          display: flex;
          button {
            margin: auto;
            width: 35%;
            background: transparent;
            border: 0;
            outline: 0;
            img {
              width: 100%;
            }
          }
        }
      }
    }
    @media (max-width: 360px) {
      .container .social-media {
        top: 9rem;
        .social-top {
          margin: 0 0.5rem;
        }
      }
    }
  `;

  const StyledMenu = styled.ul`
    display: flex;
    flex-direction: row-reverse;
    margin-right: 2rem;
    list-style: none;
    li {
      margin-left: 2rem;
      z-index: 10;
      .active-page {
        color: ${props => props.theme.colors.red};
      }
      a {
        text-decoration: none;
        color: #333333;
        font-family: ${props => props.theme.fonts.cairo};
        font-weight: 600;
        font-size: 1.6rem;
        &:hover {
          color: ${props => props.theme.colors.red};
        }
      }
    }
    @media (min-width: 1920px) {
      margin-right: 5rem;
      li {
        margin-left: 3.5rem;
        a {
          font-size: 1.8rem;
        }
      }
    }
  `;

  const StyledMobileMenu = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(6, 6, 6, 0.9);
    z-index: 100;
    transition: 0.3s transform;
    transform: translate3d(100%, 0, 0);
    ul {
      display: flex;
      flex-direction: column;
      height: 100%;
      align-items: flex-end;
      justify-content: center;
      list-style: none;
      padding: 0;
      margin-right: 10%;
      li {
        margin-bottom: 3rem;
        a {
          font-size: 2rem;
          text-decoration: none;
          font-family: ${props => props.theme.fonts.cairo};
          color: white;
        }
        .active-page {
          color: #ffffff;
          background: #df1f26;
          padding: 1rem 2rem;
          font-weight: bold;
        }
      }
    }
    button {
      position: absolute;
      top: 5rem;
      left: 3rem;
      background: transparent;
      padding: 1rem;
      border: 0;
      img {
        width: 30px;
      }
    }
  `;

  return (
    <StaticQuery
      query={graphql`
        {
          contentfulMenu {
            menuItems {
              title
              url
            }
          }
          contentfulContactDetails {
            email
            phone
          }
          contentfulSocialMediaInfo {
            facebookLink
            twitterLink
            instagramLink
            behanceLink
            youtubeLink
            linkedInLink
          }
        }
      `}
      render={data => (
        <>
          <StyleTopHeader>
            <div className="change-language">
              <Link to="/">
                <span>EN</span>
              </Link>
              <Link to="/ar">
                <span>AR</span>
              </Link>
            </div>
            <div className="inline-icons">
              <div className="inline-icon">
                <span className="icon-email"></span>
                <p>
                  <a href={`mailto:${data.contentfulContactDetails.email}`}>
                    {data.contentfulContactDetails.email}
                  </a>
                </p>
              </div>
              <div className="inline-icon">
                <span className="icon-phone"></span>
                <p>
                  <a
                    href={`https://wa.me/${data.contentfulContactDetails.phone}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {data.contentfulContactDetails.phone}
                  </a>
                </p>
              </div>
            </div>
          </StyleTopHeader>
          <StyledHeader>
            <div className="container">
              <div className="social-media">
                <a
                  href={data.contentfulSocialMediaInfo.facebookLink}
                  target="_blank"
                  className="social-top"
                  rel="noopener noreferrer"
                >
                  <span className="icon-facebook">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </span>
                </a>
                <a
                  href={data.contentfulSocialMediaInfo.twitterLink}
                  target="_blank"
                  className="social-top"
                  rel="noopener noreferrer"
                >
                  <span className="icon-twitter">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </span>
                </a>
                <a
                  href={data.contentfulSocialMediaInfo.instagramLink}
                  target="_blank"
                  className="social-top"
                  rel="noopener noreferrer"
                >
                  <span className="icon-instagram">
                    <span className="path1"></span>
                    <span className="path2"></span>
                    <span className="path3"></span>
                    <span className="path4"></span>
                  </span>
                </a>
                <a
                  href={data.contentfulSocialMediaInfo.youtubeLink}
                  target="_blank"
                  className="social-top"
                  rel="noopener noreferrer"
                >
                  <span className="icon-youtube">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </span>
                </a>
                <a
                  href={data.contentfulSocialMediaInfo.behanceLink}
                  target="_blank"
                  className="social-top"
                  rel="noopener noreferrer"
                >
                  <span className="icon-behance">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </span>
                </a>
                <a
                  href={data.contentfulSocialMediaInfo.linkedInLink}
                  target="_blank"
                  className="social-top"
                  rel="noopener noreferrer"
                >
                  <span className="icon-linkedin">
                    <span className="path1"></span>
                    <span className="path2"></span>
                    <span className="path3"></span>
                  </span>
                </a>
              </div>
              <div className="mob-menu">
                <button onClick={openMenu}>
                  <img src={MobileMenu} alt="mobile menu" />
                </button>
              </div>
              <div className="menu">
                <motion.div
                  initial={{ x: 50, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ duration: 0.5 }}
                >
                  <StyledMenu>
                    {data.contentfulMenu.menuItems.map((item, i) => (
                      <li key={i}>
                        <Link
                          activeClassName="active-page"
                          to={`/ar/${item.url}`}
                        >
                          {item.title}
                        </Link>
                      </li>
                    ))}
                  </StyledMenu>
                </motion.div>
                <Link to="/ar" className="logo">
                  <img src={Logo} alt="3box logo" />
                </Link>
              </div>
            </div>
          </StyledHeader>
          <StyledMobileMenu id="styled-mobile-menu">
            <button onClick={closeMenu} className="close-menu-button">
              <img src={CloseMenu} alt="close menu" />
            </button>
            <ul>
              {data.contentfulMenu.menuItems.map((item, i) => (
                <li key={i}>
                  <Link activeClassName="active-page" to={`/ar/${item.url}`}>
                    {item.title}
                  </Link>
                </li>
              ))}
            </ul>
          </StyledMobileMenu>
        </>
      )}
    />
  );
};

export default Menu;
