import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { StyledDetails, StyledHeader } from '../components/styles/contact';
import Logo from '../images/logo-white-bg.svg';

const contact = ({ data }) => {
  return (
    <Layout>
      <SEO title="Contact Us" />
      <StyledHeader
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.5 }}
      >
        <Img fluid={data.contentfulContactDetails.contactImg.fluid} />
      </StyledHeader>
      <StyledDetails>
        <div className="block">
          <div className="header">
            <img src={Logo} alt="3box" />
            <h2>{data.contentfulContactDetailsEnglish.firstBoxHeading}</h2>
          </div>
          <div className="info">
            <div className="info-left">
              <h2>
                {data.contentfulContactDetailsEnglish.firstRowRightHeading}
              </h2>
              <p>{data.contentfulContactDetailsEnglish.firstRowRightContent}</p>
            </div>
            <div className="info-right">
              <img
                src={
                  data.contentfulContactDetailsEnglish.contactUsIconOne.file.url
                }
                alt="address icon"
              />
            </div>
          </div>
          <div className="info">
            <div className="info-left">
              <h2>
                {data.contentfulContactDetailsEnglish.secondRowRightHeading}
              </h2>
              <p>
                {data.contentfulContactDetailsEnglish.secondRowRightContent}
              </p>
            </div>
            <div className="info-right">
              <img
                src={
                  data.contentfulContactDetailsEnglish.contactUsIconThree.file
                    .url
                }
                alt="phone icon"
              />
            </div>
          </div>
          <div className="info">
            <div className="info-left">
              <h2>
                {data.contentfulContactDetailsEnglish.thirdRowRightHeading}
              </h2>
              <p>{data.contentfulContactDetailsEnglish.thirdRowRightContent}</p>
            </div>
            <div className="info-right">
              <img
                src={
                  data.contentfulContactDetailsEnglish.contactUsIconFive.file
                    .url
                }
                alt="address icon"
              />
            </div>
          </div>
        </div>
        <div className="block">
          <div className="header">
            <img src={Logo} alt="3box" />
            <h2>{data.contentfulContactDetailsEnglish.secondBoxHeading}</h2>
          </div>
          <div className="info">
            <div className="info-left">
              <h2>
                {data.contentfulContactDetailsEnglish.firstRowLeftHeading}
              </h2>
              <p>{data.contentfulContactDetailsEnglish.firstRowLeftContent}</p>
            </div>
            <div className="info-right">
              <img
                src={
                  data.contentfulContactDetailsEnglish.contactUsIconTwo.file.url
                }
                alt="address icon"
              />
            </div>
          </div>
          <div className="info">
            <div className="info-left">
              <h2>
                {data.contentfulContactDetailsEnglish.secondRowLeftHeading}
              </h2>
              <p>{data.contentfulContactDetailsEnglish.secondRowLeftContent}</p>
            </div>
            <div className="info-right">
              <img
                src={
                  data.contentfulContactDetailsEnglish.contactUsIconFour.file
                    .url
                }
                alt="address icon"
              />
            </div>
          </div>
          <div className="info">
            <div className="info-left">
              <h2>
                {data.contentfulContactDetailsEnglish.thirdRowLeftHeading}
              </h2>
              <p>{data.contentfulContactDetailsEnglish.thirdRowLeftContent}</p>
            </div>
            <div className="info-right">
              <img
                src={
                  data.contentfulContactDetailsEnglish.contactUsIconSix.file.url
                }
                alt="address icon"
              />
            </div>
          </div>
        </div>
      </StyledDetails>
    </Layout>
  );
};

export const data = graphql`
  {
    contentfulContactDetails {
      contactImg {
        fluid(maxWidth: 1920) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
    contentfulContactDetailsEnglish {
      firstBoxHeading
      secondBoxHeading
      firstRowRightHeading
      firstRowRightContent
      contactUsIconOne {
        file {
          url
        }
      }

      secondRowRightHeading
      secondRowRightContent
      contactUsIconTwo {
        file {
          url
        }
      }

      thirdRowRightHeading
      thirdRowRightContent
      contactUsIconThree {
        file {
          url
        }
      }

      firstRowLeftHeading
      firstRowLeftContent
      contactUsIconFour {
        file {
          url
        }
      }

      secondRowLeftHeading
      secondRowLeftContent
      contactUsIconFive {
        file {
          url
        }
      }

      thirdRowLeftHeading
      thirdRowLeftContent
      contactUsIconSix {
        file {
          url
        }
      }
    }
  }
`;

export default contact;
