import { graphql, StaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import React from 'react';
import { Link } from '../LinkWithBack';

const Categories = ({ linkState }) => {
  return (
    <StaticQuery
      query={graphql`
        {
          allContentfulCategories(sort: { fields: createdAt, order: DESC }) {
            edges {
              node {
                name
                slug
                icon {
                  file {
                    url
                  }
                }
                background {
                  fluid(maxWidth: 1920) {
                    ...GatsbyContentfulFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
        }
      `}
      render={data => (
        <ul className="categories">
          {data.allContentfulCategories.edges.map(category => (
            <li
              id={category.node.slug}
              key={category.node.slug}
              className="category"
            >
              <Link
                state={{ id: category.node.slug }}
                to={`/ar/category/${category.node.slug}`}
              >
                <BackgroundImage
                  Tag="div"
                  fluid={category.node.background.fluid}
                  backgroundColor={`#f5f5f5`}
                >
                  <img
                    src={category.node.icon.file.url}
                    alt={category.node.name}
                  />
                  <h2>{category.node.name}</h2>
                </BackgroundImage>
              </Link>
            </li>
          ))}
        </ul>
      )}
    />
  );
};

export default Categories;
