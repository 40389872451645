import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import Img from 'gatsby-image';
import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { Link } from '../components/LinkWithBack';
import {
  GeneralHeading,
  StyledCategories,
  StyledServices
} from '../components/styles';

const Services = ({ data, location }) => {
  const featuredCategoryOne =
    data.contentfulFeaturedCategoriesEnglish.categoryOne.englishName;
  const featuredCategoryTwo =
    data.contentfulFeaturedCategoriesEnglish.categoryTwo.englishName;
  const featuredCategoryThree =
    data.contentfulFeaturedCategoriesEnglish.categoryThree.englishName;

  const renderCategory = category => {
    if (
      category.node.englishName !== featuredCategoryOne &&
      category.node.englishName !== featuredCategoryTwo &&
      category.node.englishName !== featuredCategoryThree
    ) {
      return (
        <li
          id={category.node.slug}
          key={category.node.slug}
          className="category"
        >
          <Link
            state={{ id: category.node.slug }}
            to={`/category/${category.node.slug}`}
          >
            <BackgroundImage
              Tag="div"
              fluid={category.node.background.fluid}
              backgroundColor={`#f5f5f5`}
            >
              <img
                src={category.node.icon.file.url}
                alt={category.node.englishName}
              />
              <h2>{category.node.englishName}</h2>
            </BackgroundImage>
          </Link>
        </li>
      );
    }
  };

  return (
    <Layout>
      <SEO title="Services" />
      <GeneralHeading>
        <h2>
          {data.contentfulFeaturedCategoriesEnglish.categoriesHeading}{' '}
          <span>
            {data.contentfulFeaturedCategoriesEnglish.categoriesHeadingBottom}{' '}
          </span>
        </h2>
      </GeneralHeading>
      <StyledServices
        initial={{
          y: -50,
          opacity: 0
        }}
        animate={{
          y: 0,
          opacity: 1
        }}
        transition={{ duration: 0.5 }}
      >
        <div
          className="service"
          id={data.contentfulFeaturedCategoriesEnglish.categoryOne.slug}
        >
          <div
            style={{
              background: `${data.contentfulFeaturedCategoriesEnglish.categoryOneColor}`
            }}
            className="service-photo"
          >
            <Img
              fluid={data.contentfulFeaturedCategoriesEnglish.photoOne.fluid}
            />
          </div>
          <div className="service-typography">
            <div className="content">
              <h2
                style={{
                  color: `${data.contentfulFeaturedCategoriesEnglish.categoryOneColor}`
                }}
              >
                {
                  data.contentfulFeaturedCategoriesEnglish.categoryOne
                    .englishName
                }
              </h2>
              {documentToReactComponents(
                data.contentfulFeaturedCategoriesEnglish.contentOne.json
              )}
              <ul className="icons">
                <li>
                  <img
                    src={
                      data.contentfulFeaturedCategoriesEnglish
                        .iconOneCategoryOne.file.url
                    }
                    alt="icon"
                  />
                  <p>
                    {
                      data.contentfulFeaturedCategoriesEnglish
                        .iconOneCategoryOne.description
                    }{' '}
                  </p>
                </li>
                <li>
                  <img
                    src={
                      data.contentfulFeaturedCategoriesEnglish
                        .iconTwoCategoryOne.file.url
                    }
                    alt="icon"
                  />
                  <p>
                    {
                      data.contentfulFeaturedCategoriesEnglish
                        .iconTwoCategoryOne.description
                    }{' '}
                  </p>
                </li>
                <li>
                  <img
                    src={
                      data.contentfulFeaturedCategoriesEnglish
                        .iconThreeCategoryOne.file.url
                    }
                    alt="icon"
                  />
                  <p>
                    {
                      data.contentfulFeaturedCategoriesEnglish
                        .iconThreeCategoryOne.description
                    }{' '}
                  </p>
                </li>
                <li>
                  <img
                    src={
                      data.contentfulFeaturedCategoriesEnglish
                        .iconFourCategoryOne.file.url
                    }
                    alt="icon"
                  />
                  <p>
                    {
                      data.contentfulFeaturedCategoriesEnglish
                        .iconFourCategoryOne.description
                    }{' '}
                  </p>
                </li>
              </ul>
              <div>
                <Link
                  state={{
                    id:
                      data.contentfulFeaturedCategoriesEnglish.categoryOne.slug
                  }}
                  style={{
                    color: `${data.contentfulFeaturedCategoriesEnglish.categoryOneColor}`,
                    border: `2px solid${data.contentfulFeaturedCategoriesEnglish.categoryOneColor}`
                  }}
                  to={`/category/${data.contentfulFeaturedCategoriesEnglish.categoryOne.slug}`}
                >
                  Read More
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div
          className="service  left-sided"
          id={data.contentfulFeaturedCategoriesEnglish.categoryTwo.slug}
        >
          <div className="service-typography">
            <div className="content left">
              <h2
                style={{
                  color: `${data.contentfulFeaturedCategoriesEnglish.categoryTwoColor}`
                }}
              >
                {
                  data.contentfulFeaturedCategoriesEnglish.categoryTwo
                    .englishName
                }
              </h2>
              {documentToReactComponents(
                data.contentfulFeaturedCategoriesEnglish.contentTwo.json
              )}
              <ul className="icons">
                <li>
                  <img
                    src={
                      data.contentfulFeaturedCategoriesEnglish
                        .iconOneCategoryTwo.file.url
                    }
                    alt="icon"
                  />
                  <p>
                    {
                      data.contentfulFeaturedCategoriesEnglish
                        .iconOneCategoryTwo.description
                    }{' '}
                  </p>
                </li>
                <li>
                  <img
                    src={
                      data.contentfulFeaturedCategoriesEnglish
                        .iconTwoCategoryTwo.file.url
                    }
                    alt="icon"
                  />
                  <p>
                    {
                      data.contentfulFeaturedCategoriesEnglish
                        .iconTwoCategoryTwo.description
                    }{' '}
                  </p>
                </li>
                <li>
                  <img
                    src={
                      data.contentfulFeaturedCategoriesEnglish
                        .iconThreeCategoryTwo.file.url
                    }
                    alt="icon"
                  />
                  <p>
                    {
                      data.contentfulFeaturedCategoriesEnglish
                        .iconThreeCategoryTwo.description
                    }{' '}
                  </p>
                </li>
                <li>
                  <img
                    src={
                      data.contentfulFeaturedCategoriesEnglish
                        .iconFourCategoryTwo.file.url
                    }
                    alt="icon"
                  />
                  <p>
                    {
                      data.contentfulFeaturedCategoriesEnglish
                        .iconFourCategoryTwo.description
                    }{' '}
                  </p>
                </li>
              </ul>
              <div>
                <Link
                  state={{
                    id:
                      data.contentfulFeaturedCategoriesEnglish.categoryTwo.slug
                  }}
                  style={{
                    color: `${data.contentfulFeaturedCategoriesEnglish.categoryTwoColor}`,
                    border: `2px solid${data.contentfulFeaturedCategoriesEnglish.categoryTwoColor}`
                  }}
                  to={`/category/${data.contentfulFeaturedCategoriesEnglish.categoryTwo.slug}`}
                >
                  Read More
                </Link>
              </div>
            </div>
          </div>
          <div
            style={{
              background: `${data.contentfulFeaturedCategoriesEnglish.categoryTwoColor}`
            }}
            className="service-photo"
          >
            <Img
              fluid={data.contentfulFeaturedCategoriesEnglish.photoTwo.fluid}
            />
          </div>
        </div>
        <div
          className="service"
          id={data.contentfulFeaturedCategoriesEnglish.categoryThree.slug}
        >
          <div
            style={{
              background: `${data.contentfulFeaturedCategoriesEnglish.categoryThreeColor}`
            }}
            className="service-photo"
          >
            <Img
              fluid={data.contentfulFeaturedCategoriesEnglish.photoThree.fluid}
            />
          </div>
          <div className="service-typography">
            <div className="content">
              <h2
                style={{
                  color: `${data.contentfulFeaturedCategoriesEnglish.categoryThreeColor}`
                }}
              >
                {
                  data.contentfulFeaturedCategoriesEnglish.categoryThree
                    .englishName
                }
              </h2>
              {documentToReactComponents(
                data.contentfulFeaturedCategoriesEnglish.contentOne.json
              )}
              <ul className="icons">
                <li>
                  <img
                    src={
                      data.contentfulFeaturedCategoriesEnglish
                        .iconOneCategoryThree.file.url
                    }
                    alt="icon"
                  />
                  <p>
                    {
                      data.contentfulFeaturedCategoriesEnglish
                        .iconOneCategoryThree.description
                    }{' '}
                  </p>
                </li>
                <li>
                  <img
                    src={
                      data.contentfulFeaturedCategoriesEnglish
                        .iconTwoCategoryThree.file.url
                    }
                    alt="icon"
                  />
                  <p>
                    {
                      data.contentfulFeaturedCategoriesEnglish
                        .iconTwoCategoryThree.description
                    }{' '}
                  </p>
                </li>
                <li>
                  <img
                    src={
                      data.contentfulFeaturedCategoriesEnglish
                        .iconThreeCategoryThree.file.url
                    }
                    alt="icon"
                  />
                  <p>
                    {
                      data.contentfulFeaturedCategoriesEnglish
                        .iconThreeCategoryThree.description
                    }{' '}
                  </p>
                </li>
                <li>
                  <img
                    src={
                      data.contentfulFeaturedCategoriesEnglish
                        .iconFourCategoryThree.file.url
                    }
                    alt="icon"
                  />
                  <p>
                    {
                      data.contentfulFeaturedCategoriesEnglish
                        .iconFourCategoryThree.description
                    }{' '}
                  </p>
                </li>
              </ul>
              <div>
                <Link
                  state={{
                    id:
                      data.contentfulFeaturedCategoriesEnglish.categoryThree
                        .slug
                  }}
                  style={{
                    color: `${data.contentfulFeaturedCategoriesEnglish.categoryThreeColor}`,
                    border: `2px solid${data.contentfulFeaturedCategoriesEnglish.categoryThreeColor}`
                  }}
                  to={`/category/${data.contentfulFeaturedCategoriesEnglish.categoryThree.slug}`}
                >
                  Read More
                </Link>
              </div>
            </div>
          </div>
        </div>
      </StyledServices>
      <StyledCategories>
        <ul className="categories">
          {data.allContentfulCategories.edges.map(category =>
            renderCategory(category)
          )}
        </ul>
      </StyledCategories>
    </Layout>
  );
};

export const data = graphql`
  {
    contentfulFeaturedCategoriesEnglish {
      categoriesHeading
      categoriesHeadingBottom
      categoryOne {
        englishName
        slug
      }
      categoryOneColor
      categoryTwoColor
      categoryThreeColor
      photoOne {
        fluid(maxWidth: 1500) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      contentOne {
        json
      }
      iconOneCategoryOne {
        file {
          url
        }
        description
      }
      iconTwoCategoryOne {
        file {
          url
        }
        description
      }
      iconThreeCategoryOne {
        file {
          url
        }
        description
      }
      iconFourCategoryOne {
        file {
          url
        }
        description
      }
      categoryTwo {
        englishName
        slug
      }
      photoTwo {
        fluid(maxWidth: 1500) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      contentTwo {
        json
      }
      iconOneCategoryTwo {
        file {
          url
        }
        description
      }
      iconTwoCategoryTwo {
        file {
          url
        }
        description
      }
      iconThreeCategoryTwo {
        file {
          url
        }
        description
      }
      iconFourCategoryTwo {
        file {
          url
        }
        description
      }
      categoryThree {
        englishName
        slug
      }
      photoThree {
        fluid(maxWidth: 1500) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      contentThree {
        json
      }
      iconOneCategoryThree {
        file {
          url
        }
        description
      }
      iconTwoCategoryThree {
        file {
          url
        }
        description
      }
      iconThreeCategoryThree {
        file {
          url
        }
        description
      }
      iconFourCategoryThree {
        file {
          url
        }
        description
      }
    }
    allContentfulCategories(sort: { fields: createdAt, order: DESC }) {
      edges {
        node {
          englishName
          slug
          icon {
            file {
              url
            }
          }
          background {
            fluid(maxWidth: 1920) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`;

export default Services;
