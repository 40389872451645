import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import { ThemeProvider } from 'styled-components';
import AppleTouchIcon from '../images/FavIcon/apple-touch-icon.png';
import Fav16 from '../images/FavIcon/favicon-16x16.png';
import Fav32 from '../images/FavIcon/favicon-32x32.png';
import SafariPinned from '../images/FavIcon/safari-pinned-tab.svg';
import '../images/icons/icomoon/icons.css';
import GlobalStyle from '../styles/global';
import theme from '../styles/theme';
import GoUp from './GoUp';
import Footer from './Footer';
import Menu from './Menu';

const Layout = ({ children }) => {
  useEffect(() => {
    document.oncontextmenu = function(e) {
      if (e.button === 2) {
        e.preventDefault();
        return false;
      }
    };

    document.onkeypress = function(event) {
      event = event || window.event;
      if (event.keyCode === 123) {
        return false;
      }
    };
    document.onmousedown = function(event) {
      event = event || window.event;
      if (event.keyCode === 123) {
        return false;
      }
    };
    document.onkeydown = function(event) {
      event = event || window.event;
      if (event.keyCode === 123) {
        return false;
      }
    };
  }, []);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <html lang="en" />
        <link rel="apple-touch-icon" sizes="180x180" href={AppleTouchIcon} />
        <link rel="icon" type="image/png" sizes="32x32" href={Fav32} />
        <link rel="icon" type="image/png" sizes="16x16" href={Fav16} />
        <link rel="mask-icon" href={SafariPinned} color="#5bbad5" />
        <link
          href="https://fonts.googleapis.com/css?family=Montserrat:500,700&display=swap"
          rel="stylesheet"
        ></link>
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="theme-color" content="#ffffff"></meta>
      </Helmet>
      <ThemeProvider theme={theme}>
        <Menu />
        <GoUp />
        <main>{children}</main>
        <Footer />
      </ThemeProvider>
      <GlobalStyle />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
