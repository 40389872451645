import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import styled from 'styled-components';
import Layout from '../components/ar/layout';
import SEO from '../components/ar/seo';

const Post = ({ data }) => {
  const StyledStaticPage = styled.div`
    font-family: ${props => props.theme.fonts.cairo};
    text-align: right;
    margin-bottom: 10rem;
    .hero {
      height: 35vh;
      .gatsby-image-wrapper {
        height: 100%;
      }
    }
    .content {
      width: 75%;
      padding: 2rem 0;
      margin: auto;
      p {
        font-size: 1.5rem;
        line-height: 4rem;
        color: #333333;
      }
    }
    .title {
      margin-top: 3rem;
      z-index: 2;
      position: relative;
      font-size: 2.5rem;
      color: ${props => props.theme.colors.red};
    }
    .date {
      font-size: 1.8rem;
      display: inline-block;
      margin-bottom: 3rem;
    }
  `;

  return (
    <Layout>
      <SEO title={data.contentfulStaticPages.title} />
      <StyledStaticPage>
        <div className="hero">
          <Img fluid={data.contentfulStaticPages.image.fluid} />
        </div>
        <div className="content">
          <h1 className="title">{data.contentfulStaticPages.title}</h1>
          {documentToReactComponents(data.contentfulStaticPages.content.json)}
        </div>
      </StyledStaticPage>
    </Layout>
  );
};

export const data = graphql`
  query($url: String!) {
    contentfulStaticPages(url: { eq: $url }) {
      title
      image {
        fluid(maxWidth: 2560) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      content {
        json
      }
    }
  }
`;

export default Post;
