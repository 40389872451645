import { Link } from '../LinkWithBack';
import Img from 'gatsby-image';
import React, { Component } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import styled from 'styled-components';

export class LatestPosts extends Component {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }
  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }
  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      autoplay: true,
      rtl: true,
      autoplaySpeed: 3000,
      slidesToShow: 3,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

    const StyledPosts = styled.div`
      width: 90%;
      margin: 10rem auto;
      font-family: ${props => props.theme.fonts.cairo};
      .post {
        text-align: right;
        border: 1px solid#d4d4d4;
        width: 96% !important;
        margin: auto !important;
        outline-color: ${props => props.theme.colors.red};
        .content {
          padding: 1.5rem;
          display: flex;
          flex-direction: column;
        }
        .photo {
          height: 30rem;
          .gatsby-image-wrapper {
            height: 100%;
          }
        }
        h2 {
          float: right;
          font-size: 1.8rem;
          font-weight: 600;
          margin-right: 0.5rem;
          a {
            color: #000000;
            text-decoration: none;
            &:hover {
              color: ${props => props.theme.colors.red};
            }
          }
        }
        .more {
          margin-right: 0.5rem;
          margin-top: 1.5rem;
          display: inline-block;
          font-size: 1.3rem;
          border: 0;
          background: ${props => props.theme.colors.red};
          color: #ffffff;
          padding: 1.5rem 3rem;
          text-decoration: none;
          &:hover {
            background: #333333;
          }
        }
      }
    `;

    const StyledButtons = styled.div`
      margin-top: 5rem;
      display: flex;
      justify-content: center;
      button {
        background: transparent;
        border: 2px solid ${props => props.theme.colors.red};
        padding: 1rem 1.5rem;
        margin: 0 0.5rem;
        font-size: 2.5rem;
        outline: 0;
        cursor: pointer;
        transition: 0.3s all;
        color: ${props => props.theme.colors.red};
        &:hover {
          background: ${props => props.theme.colors.red};
          color: #ffffff;
        }
      }
    `;
    return (
      <StyledPosts>
        <Slider ref={c => (this.slider = c)} {...settings}>
          {this.props.allPosts.map(post => (
            <div id={post.node.slug} key={post.node.slug} className="post">
              <div className="content">
                <div className="photo">
                  <Img fluid={post.node.hero.fluid} />
                </div>
                <h2>
                  <Link
                    state={{ id: post.node.slug }}
                    to={`/ar/blog/${post.node.slug}`}
                  >
                    {post.node.title}
                  </Link>
                </h2>
                <div>
                  <Link
                    state={{ id: post.node.slug }}
                    className="more"
                    to={`/ar/blog/${post.node.slug}`}
                  >
                    اقرا المزيد
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </Slider>
        <StyledButtons>
          <button aria-label="previous" onClick={this.previous}>
            <span className="icon-chevron-left">
              <span className="path1"></span>
              <span className="path2"></span>
            </span>
          </button>
          <button aria-label="next" onClick={this.next}>
            <span className="icon-chevron-right">
              <span className="path1"></span>
              <span className="path2"></span>
            </span>
          </button>
        </StyledButtons>
      </StyledPosts>
    );
  }
}

export default LatestPosts;
