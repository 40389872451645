import styled from 'styled-components';

export const ProjectsHeader = styled.div`
  width: 90%;
  margin: 8rem auto 0;
  font-family: ${props => props.theme.fonts.cairo};
  .photo {
    background: ${props => props.theme.colors.red};
    height: 51.9rem;
    width: 100%;
    .gatsby-image-wrapper {
      height: 100%;
    }
  }
  .header-content {
    min-width: 25%;
    float: right;
    h1 {
      margin-top: -5rem;
      margin-right: 2rem;
      background: #fbfbfb;
      position: relative;
      text-align: center;
      padding: 2rem;
    }
  }
  @media (min-width: 2560px) {
    .photo {
      height: 62rem;
    }
  }
  @media (min-width: 1920px) and (max-width: 2550px) {
    .photo {
      height: 57rem;
    }
  }
  @media (max-width: 1024px) {
    .photo {
      height: 38rem;
    }
  }
  @media (max-width: 768px) {
    flex-direction: column;
    margin: 4rem auto;
    .photo {
      height: 29rem;
    }
    .header {
      width: 100%;
      text-align: center;
      margin-top: 2rem;
      p {
        width: 100%;
      }
    }
    .header-content h1 {
      font-size: 1.5rem;
    }
  }

  @media (max-width: 414px) {
    margin-top: 8rem;
    .header-content {
      width: 100%;
      h1 {
        margin-top: 0;
        margin-right: 0;
      }
    }
    .photo {
      height: 15rem;
    }
  }
`;

export const ProjectsFilters = styled.ul`
  list-style: none;
  width: 25%;
  li {
    margin: 0 1rem;
    width: 95%;
    .current-type {
      border-color: ${props => props.theme.colors.red};
      background: ${props => props.theme.colors.red};
      color: white;
    }
    a {
      color: #000000;
      width: 100%;
      text-decoration: none;
      font-size: 1.4rem;
      font-family: ${props => props.theme.fonts.cairo};
      border: 1px solid#333333;
      padding: 2rem 0;
      text-align: center;
      margin-bottom: 1rem;
      display: inline-block;
      &:hover {
        border-color: ${props => props.theme.colors.red};
        color: ${props => props.theme.colors.red};
      }
    }
  }
  @media (min-width: 1920px) and (max-width: 2550px) {
    li a {
      font-size: 1.8rem;
      padding: 2.6rem;
    }
  }
  @media (max-width: 1024px) {
    justify-content: center;
  }
  @media (max-width: 450px) {
    width: 100%;
  }
`;

export const StyledProjects = styled.div`
  display: flex;
  align-items: flex-start;
  margin: 0 auto 8rem;
  width: 90%;
  font-family: ${props => props.theme.fonts.cairo};
  .go-to-behance {
    display: flex;
    justify-content: space-between;
    a {
      text-decoration: none;
      padding: 2rem 3rem;
      background: #333333;
      border-radius: 3rem;
      color: #ffffff;
      font-size: 1.4rem;
      font-weight: 700;
      display: flex;
      align-items: center;
      span {
        font-size: 2rem;
        margin: 0 0.3rem;
      }
    }
  }
  .left {
    width: 75%;
    .projects {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
    }
  }
  .from-contentful {
    width: 75%;
    margin-right: 2rem;
    text-align: right;
    .content-from-contentful {
      text-align: right;
      direction: rtl;
      margin-right: 2rem;
      h1 {
        font-size: 2.6rem;
      }
      h2 {
        font-size: 2.4rem;
      }
      p {
        font-size: 1.4rem;
        line-height: 3rem;
        color: #333333;
        b {
          font-size: 1.6rem;
        }
      }
    }
    .slider-images {
      width: 100%;
      margin: 5rem 0;
      .gatsby-image-wrapper {
        height: 35rem;
      }
      .slick-dots {
        bottom: -4rem;
        li button:before {
          font-size: 9px;
        }
        .slick-active {
          button:before {
            color: ${props => props.theme.colors.red};
          }
        }
      }
    }
  }
  .load-more {
    text-align: center;
    margin: 2rem 0 5rem;
    button {
      background: transparent;
      border-radius: 3rem;
      border: 2px solid ${props => props.theme.colors.red};
      outline: 0;
      padding: 1.8rem 4rem;
      cursor: pointer;
      transition: 0.4s all;
      font-size: 1.5rem;
      &:hover {
        background: ${props => props.theme.colors.red};
        color: #ffffff;
      }
    }
  }
  .project {
    width: 32%;
    margin: 0 0.6rem 3rem;
    text-align: right;
    a {
      text-decoration: none;
      color: #000000;
      &:hover {
        color: ${props => props.theme.colors.red};
      }
    }
    h3 {
      font-size: 1.6rem;
      margin-bottom: 0;
      margin-right: 0.5rem;
    }
    p {
      font-size: 1.3rem;
      color: #333333;
      margin-right: 0.5rem;
    }
    .gatsby-image-wrapper {
      height: 22rem;
    }
  }
  @media (min-width: 2560px) {
    .project .gatsby-image-wrapper {
      height: 27rem;
    }
  }
  @media (min-width: 1920px) and (max-width: 2550px) {
    .project {
      margin: 0 0.6rem 3rem;
      .gatsby-image-wrapper {
        height: 25rem;
      }
    }
  }
  @media (max-width: 1024px) {
    .project {
      width: 31%;
      .gatsby-image-wrapper {
        height: 15rem;
      }
      h3 {
        font-size: 1.4rem;
      }
      p {
        margin-top: 0.5rem;
      }
    }
  }
  @media (max-width: 768px) {
    .project {
      width: 47%;
    }
  }
  @media (max-width: 450px) {
    flex-direction: column;
    .left {
      width: 100%;
    }
    .project {
      width: 100%;
    }
    .from-contentful {
      width: 100%;
      margin: 0;
      .slider-images .gatsby-image-wrapper {
        height: 28rem;
      }
    }
    .go-to-behance {
      margin-bottom: 4rem;
      a {
        padding: 2rem;
      }
    }
  }
  @media (max-width: 360px) {
    .go-to-behance a {
      padding: 1.5rem;
      font-size: 1.2rem;
    }
  }
`;

export const ShowMore = styled.button`
  color: ${props => props.theme.colors.red};
  border: 2px solid ${props => props.theme.colors.red};
  background: transparent;
  font-family: ${props => props.theme.fonts.cairo};
  padding: 1.5rem 3rem;
  cursor: pointer;
  font-size: 2rem;
  border-radius: 3rem;
  margin: 0 auto 10rem;
  display: flex;
  &:hover {
    color: #000000;
    border-color: #000000;
  }
`;

export const ControlButtons = styled.div`
  display: flex;
  width: 90%;
  margin: 0 auto 4rem;
  a {
    padding: 1.5rem 3rem;
    background: #e8e7e7;
    text-decoration: none;
    color: #ffffff;
    margin-right: 2rem;
    font-family: ${props => props.theme.fonts.cairo};
    font-size: 1.4rem;
    border-radius: 1rem;
    &:hover {
      background: ${props => props.theme.colors.red};
    }
  }
  @media (max-width: 450px) {
    justify-content: space-between;
    a {
      margin-right: 0;
    }
  }
`;
