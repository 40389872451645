import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { motion } from 'framer-motion';
import { graphql } from 'gatsby';
import React from 'react';
import KnowMore from '../components/KnowMore';
import Layout from '../components/layout';
import SEO from '../components/seo';
import WhyUs from '../components/WhyUs';
import { AboutHeader } from '../components/styles/about';

const About = ({ data }) => {
  const renderAssets = asset => {
    if (asset.file.contentType.includes('video')) {
      return (
        <video loop controls>
          <source src={asset.file.url} />
        </video>
      );
    } else {
      return <img src={asset.file.url} alt="know more" />;
    }
  };

  return (
    <Layout>
      <SEO title="About Us" />
      <AboutHeader>
        <motion.div
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="header"
        >
          <h1>{data.AboutUsContent.headingEn}</h1>
          {documentToReactComponents(data.AboutUsContent.contentEn.json)}
        </motion.div>
        <motion.div
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.5 }}
          className="photo"
        >
          {renderAssets(data.AboutUsContent.image)}
        </motion.div>
      </AboutHeader>
      <KnowMore />
      <WhyUs headings={data.AboutUsContent} data={data} />
    </Layout>
  );
};

export const data = graphql`
  {
    HomepageContent: contentfulHomepage(name: { eq: "Homepage" }) {
      whyUsHeading
      whyUsHeadingBottom
    }
    contentfulAboutUs {
      whyUsBoxOneIcon {
        file {
          url
        }
      }
      whyUsBoxTwoIcon {
        file {
          url
        }
      }
      whyUsBoxThreeIcon {
        file {
          url
        }
      }
      whyUsBoxFourIcon {
        file {
          url
        }
      }
      whyUsHeadingOneEn
      whyUsHeadingTwoEn
      whyUsHeadingThreeEn
      whyUsHeadingFourEn
      whyUsBoxOneEn {
        json
      }
      whyUsBoxTwoEn {
        json
      }
      whyUsBoxThreeEn {
        json
      }
      whyUsBoxFourEn {
        json
      }
    }
    AboutUsContent: contentfulAboutUs {
      whyUsHeading
      whyUsHeadingEn
      whyUsHeadingBottom
      whyUsHeadingBottomEn
      image {
        file {
          url
          contentType
        }
      }
      headingEn
      contentEn {
        json
      }
    }
  }
`;

export default About;
