import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import React, { useEffect, useState } from 'react';
import Categories from '../../components/ar/Categories';
import HeaderCarousel from '../../components/ar/HeaderCarousel';
import KnowMore from '../../components/ar/KnowMore';
import LatestPosts from '../../components/ar/LatestPosts';
import Layout from '../../components/ar/layout';
import { Link } from '../../components/ar/LinkWithBack';
import OurPartners from '../../components/ar/OurPartners';
import SEO from '../../components/ar/seo';
import SocialFeeds from '../../components/ar/SocialFeeds';
import {
  GeneralHeading,
  StyledCategories,
  StyledProjects
} from '../../components/styles/ar';
import WhyUs from '../../components/ar/WhyUs';

const IndexPage = ({ data, location }) => {
  const [currentURL, setCurrentURL] = useState('');

  useEffect(() => {
    if (typeof window == 'undefined') {
      return setCurrentURL(location.pathname);
    }
  }, [location.pathname]);

  return (
    <Layout>
      <SEO title="الرئيسية" />
      <HeaderCarousel featuredPartners={data.featuredPartners.edges} />
      <KnowMore />
      <WhyUs data={data} />
      <GeneralHeading>
        <h2>
          {data.contentfulPreviousWorkCategories.categoriesHeading}{' '}
          <span>
            {data.contentfulPreviousWorkCategories.categoriesHeadingBottom}
          </span>
        </h2>
      </GeneralHeading>
      <StyledCategories>
        <Categories linkState={currentURL} />
      </StyledCategories>
      <GeneralHeading>
        <h2>
          {data.contentfulPreviousWorkCategories.projectsHeading}{' '}
          <span>
            {data.contentfulPreviousWorkCategories.projectsHeadingBottom}
          </span>
        </h2>
      </GeneralHeading>
      <StyledProjects>
        <div className="projects">
          {data.allProjects.edges.map(project => (
            <div key={project.node.title} className="project">
              <a
                href={project.node.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Img fluid={project.node.hero.fluid} />
              </a>
              <a
                href={project.node.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <h3>{project.node.title}</h3>
              </a>
              <p>{project.node.category.name}</p>
            </div>
          ))}
        </div>
        <div className="more">
          <Link state={currentURL} to="/ar/projects">
            المزيد من اعمالنا
          </Link>
        </div>
      </StyledProjects>
      <GeneralHeading>
        <h2>
          {data.contentfulBlog.blogHeadingHomepage}{' '}
          <span>{data.contentfulBlog.blogHeadingHomepageBottom}</span>
        </h2>
      </GeneralHeading>
      <LatestPosts allPosts={data.allPosts.edges} />
      <SocialFeeds data={data.contentfulSocialMediaInfo} />
      <OurPartners
        headings={data.contentfulOurPartners}
        dataHomepage={data.HomepageContent}
      />
    </Layout>
  );
};

export const data = graphql`
  {
    HomepageContent: contentfulHomepage(name: { eq: "Homepage" }) {
      whyUsHeading
      whyUsHeadingBottom
    }
    contentfulAboutUs {
      whyUsBoxOneIcon {
        file {
          url
        }
      }
      whyUsBoxTwoIcon {
        file {
          url
        }
      }
      whyUsBoxThreeIcon {
        file {
          url
        }
      }
      whyUsBoxFourIcon {
        file {
          url
        }
      }
      whyUsHeadingOne
      whyUsHeadingTwo
      whyUsHeadingThree
      whyUsHeadingFour
      whyUsBoxOne {
        json
      }
      whyUsBoxTwo {
        json
      }
      whyUsBoxThree {
        json
      }
      whyUsBoxFour {
        json
      }
    }
    contentfulPreviousWorkCategories {
      categoriesHeading
      categoriesHeadingBottom
      projectsHeading
      projectsHeadingBottom
    }
    contentfulBlog {
      blogHeadingHomepage
      blogHeadingHomepageBottom
    }
    contentfulSocialMediaInfo {
      socialMediaHeading
      socialMediaHeadingBottom
      facebookLink
      twitterLink
      instagramPostURL
    }
    contentfulOurPartners {
      homepagePartners
      homepagePartnersBottom
    }
    allProjects: allContentfulProjects(
      limit: 3
      sort: { fields: [createdAt], order: DESC }
    ) {
      edges {
        node {
          title
          category {
            name
          }
          hero {
            fluid(maxWidth: 1920) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          url
          slug
        }
      }
    }
    allPosts: allContentfulPosts(
      limit: 6
      sort: { fields: [createdAt], order: DESC }
    ) {
      edges {
        node {
          title
          slug
          hero {
            fluid(maxWidth: 600) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
      }
    }
    featuredPartners: allContentfulPartners(
      limit: 5
      filter: { featured: { eq: true } }
      sort: { fields: [updatedAt], order: DESC }
    ) {
      edges {
        node {
          partnerName
          logo {
            fluid(maxWidth: 250) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`;
export default IndexPage;
