import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { motion } from 'framer-motion';
import { graphql } from 'gatsby';
import React from 'react';
import KnowMore from '../../components/ar/KnowMore';
import Layout from '../../components/ar/layout';
import SEO from '../../components/ar/seo';
import { AboutHeader } from '../../components/styles/ar/about';
import WhyUs from '../../components/ar/WhyUs';

const About = ({ data }) => {
  const renderAssets = asset => {
    if (asset.file.contentType.includes('video')) {
      return (
        <video loop controls>
          <source src={asset.file.url} />
        </video>
      );
    } else {
      return <img src={asset.file.url} alt="know more" />;
    }
  };

  return (
    <Layout>
      <SEO title="من نحن" />
      <AboutHeader>
        <motion.div
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.5 }}
          className="photo"
        >
          {renderAssets(data.AboutUsContent.image)}
        </motion.div>
        <motion.div
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="header"
        >
          <h1>{data.AboutUsContent.heading}</h1>
          {documentToReactComponents(data.AboutUsContent.content.json)}
        </motion.div>
      </AboutHeader>
      <KnowMore />
      <WhyUs data={data} />
    </Layout>
  );
};

export const data = graphql`
  {
    HomepageContent: contentfulHomepage(name: { eq: "Homepage" }) {
      whyUsHeading
      whyUsHeadingBottom
    }
    contentfulAboutUs {
      whyUsBoxOneIcon {
        file {
          url
        }
      }
      whyUsBoxTwoIcon {
        file {
          url
        }
      }
      whyUsBoxThreeIcon {
        file {
          url
        }
      }
      whyUsBoxFourIcon {
        file {
          url
        }
      }
      whyUsHeadingOne
      whyUsHeadingTwo
      whyUsHeadingThree
      whyUsHeadingFour
      whyUsBoxOne {
        json
      }
      whyUsBoxTwo {
        json
      }
      whyUsBoxThree {
        json
      }
      whyUsBoxFour {
        json
      }
    }
    AboutUsContent: contentfulAboutUs {
      image {
        file {
          url
          contentType
        }
      }
      heading
      content {
        json
      }
    }
  }
`;

export default About;
