import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Layout from '../components/ar/layout';
import { Link } from '../components/ar/LinkWithBack';
import SEO from '../components/ar/seo';
import { ControlButtons } from '../components/styles/ar/projects';
import ArrowBackIcon from '../images/icons/arrow-back.svg';
import GoHomeIcon from '../images/icons/go-home.svg';

const Post = ({ data, location }) => {
  const [currentURL, setCurrentURL] = useState('');

  useEffect(() => {
    if (typeof window == 'undefined') {
      return setCurrentURL(location.pathname);
    }
  }, [location.pathname]);

  const renderBack = () => {
    if (typeof window !== 'undefined') {
      if (location.state && location.state.prevUrl) {
        return (
          <Link
            state={currentURL}
            to={`${location.state.prevUrl}#${location.state.id}`}
          >
            <img src={ArrowBackIcon} alt="go back" />
          </Link>
        );
      }
    }
  };

  const StyledPost = styled.div`
    font-family: ${props => props.theme.fonts.cairo};
    text-align: right;
    margin-bottom: 10rem;
    .hero {
      height: 50vh;
      margin-bottom: 4rem;
      .gatsby-image-wrapper {
        height: 100%;
      }
    }
    .content {
      width: 75%;
      margin: auto;
      direction: rtl;
      p {
        font-size: 1.5rem;
        line-height: 3rem;
        color: #333333;
      }
    }
    .title {
      margin-top: 3rem;
      z-index: 2;
      position: relative;
      font-size: 2.5rem;
      color: ${props => props.theme.colors.red};
    }
    .date {
      font-size: 1.8rem;
      display: inline-block;
      margin-bottom: 3rem;
    }
  `;

  return (
    <Layout>
      <SEO title={data.contentfulPosts.title} />
      <StyledPost>
        <div className="hero">
          <Img fluid={data.contentfulPosts.hero.fluid} />
        </div>
        <ControlButtons>
          {renderBack()}
          <Link state={currentURL} to="/">
            <img src={GoHomeIcon} alt="go home" />
          </Link>
        </ControlButtons>
        <div className="content">
          <h1 className="title">{data.contentfulPosts.title}</h1>
          <span className="date">{data.contentfulPosts.createdAt}</span>
          {documentToReactComponents(data.contentfulPosts.content.json)}
        </div>
      </StyledPost>
    </Layout>
  );
};

export const data = graphql`
  query($slug: String!) {
    contentfulPosts(slug: { eq: $slug }) {
      title
      hero {
        fluid {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      content {
        json
      }
      createdAt(formatString: "MMMM Do, YYYY")
    }
  }
`;

export default Post;
