import React from 'react';
import InstagramEmbed from 'react-instagram-embed';
import { TwitterTimelineEmbed } from 'react-twitter-embed';
import styled from 'styled-components';
import { GeneralHeading } from '../styles/ar/index';

const SocialFeeds = ({ data }) => {
  const StyledSocialMedia = styled.div`
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin: 10rem auto;
    .social {
      width: 31%;
      position: relative;
      padding: 4rem 2rem;
      background: #f7f7f7;
      margin: 0 0.5rem;
      border: 15px solid#eceaea;
      .heading {
        position: absolute;
        top: -3.5rem;
        left: 50%;
        transform: translateX(-50%);
        font-size: 2.5rem;
        border-radius: 50%;
        background: ${props => props.theme.colors.red};
        padding: 1.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      iframe {
        width: 100%;
        height: 50rem;
      }
      ._2p3a {
        width: 100% !important;
      }
      .instagram-media-rendered {
        width: 100% !important;
        min-width: 0 !important;
      }
    }
    @media (min-width: 1920px) {
      .social {
        border: 2px solid#d6d6d6;
      }
    }
    @media (max-width: 768px) {
      flex-wrap: wrap;
      justify-content: center;
      .social {
        width: 80%;
        margin-top: 3rem;
      }
    }
  `;
  return (
    <>
      <GeneralHeading>
        <h2>
          {data.socialMediaHeading} <span>{data.socialMediaHeadingBottom}</span>
        </h2>
      </GeneralHeading>
      <StyledSocialMedia>
        <div className="social">
          <div className="heading">
            <span className="icon-facebook">
              <span className="path1"></span>
              <span className="path2"></span>
            </span>
          </div>
          <iframe
            src={`https://www.facebook.com/plugins/page.php?href=https%3A%2F%2F${data.facebookLink}&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=2186738638294469`}
            style={{ border: 0, overflow: 'hidden' }}
            width={100 + '%'}
            scrolling="no"
            frameBorder="0"
            allowtransparency="true"
            allow="encrypted-media"
            title="facebook feed"
          ></iframe>
        </div>
        <div className="social">
          <div className="heading">
            <span className="icon-instagram">
              <span className="path1"></span>
              <span className="path2"></span>
              <span className="path3"></span>
              <span className="path4"></span>
            </span>
          </div>
          <InstagramEmbed
            url={data.instagramPostURL}
            height={400}
            maxWidth={100 + '%'}
            hideCaption={true}
            containerTagName="div"
          />
        </div>
        <div className="social">
          <div className="heading">
            <span className="icon-twitter">
              <span className="path1"></span>
              <span className="path2"></span>
            </span>
          </div>
          <TwitterTimelineEmbed
            sourceType="profile"
            screenName="3boxhost"
            options={{ height: 500 }}
          />
        </div>
      </StyledSocialMedia>
    </>
  );
};

export default SocialFeeds;
