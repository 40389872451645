import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { motion } from 'framer-motion';
import { graphql, StaticQuery } from 'gatsby';
import React, { Component } from 'react';
import Slider from 'react-slick';
import Typed from 'react-typed';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import styled from 'styled-components';
import { StyledHero } from './styles';
import OurFeaturedPartners from './OurFeaturedPartners';
import { Link } from './LinkWithBack';

class HeaderCarousel extends Component {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }
  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }
  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 800,
      autoplay: true,
      autoplaySpeed: 10000,
      slidesToShow: 1,
      slidesToScroll: 1,
      pauseOnHover: false,
      pauseOnFocus: false,
      fade: true
    };

    const StyledCarousel = styled.div`
      overflow: hidden;
      position: relative;
      margin-top: 2rem;
      @media (max-width: 768px) {
        height: auto;
      }
    `;
    const StyledSliderItem = styled.div`
      outline: 0;
      position: relative;
      z-index: 5;
      height: 100vh;
      @media (min-width: 1920px) {
        height: 80vh;
      }
      @media (max-width: 768px) {
        height: auto;
      }
    `;
    return (
      <StaticQuery
        query={graphql`
          {
            allContentfulSlider {
              edges {
                node {
                  color
                  background {
                    file {
                      url
                    }
                  }
                  imageEn {
                    file {
                      url
                    }
                  }
                  headingOneEn
                  headingTwoEn
                  contentEn {
                    json
                  }
                  buttonUrl
                }
              }
            }
          }
        `}
        render={data => (
          <StyledCarousel>
            <Slider ref={c => (this.slider = c)} {...settings}>
              {data.allContentfulSlider.edges.map((slider, i) => {
                const sliderTypo = () => {
                  return (
                    <Typed
                      strings={[
                        slider.node.headingOneEn,
                        slider.node.headingTwoEn
                      ]}
                      typeSpeed={90}
                      backSpeed={15}
                      loop={true}
                    />
                  );
                };
                return (
                  <StyledSliderItem key={i}>
                    <StyledHero color={slider.node.color}>
                      <motion.div
                        variants={{
                          visible: { y: 0, opacity: 1 },
                          hidden: { y: -100, opacity: 0 }
                        }}
                        initial="hidden"
                        animate="visible"
                        transition={{ duration: 0.3 }}
                        className="content"
                      >
                        <h1>{sliderTypo()}</h1>
                        <div className="typo">
                          {documentToReactComponents(
                            slider.node.contentEn.json
                          )}
                        </div>
                        <div className="more">
                          <Link to={`${slider.node.buttonUrl}`}>Read More</Link>
                        </div>
                        <div className="carousel-buttons">
                          <button onClick={this.previous} className="prev">
                            {' '}
                            <span className="icon-chevron-left">
                              <span className="path1"></span>
                              <span className="path2"></span>
                            </span>
                          </button>
                          <button onClick={this.next} className="next">
                            {' '}
                            <span className="icon-chevron-right">
                              <span className="path1"></span>
                              <span className="path2"></span>
                            </span>
                          </button>
                        </div>
                        <div className="partners-sec">
                          <h2>Previous Clients</h2>
                          <div className="partners">
                            <OurFeaturedPartners
                              featuredPartners={this.props.featuredPartners}
                            />
                          </div>
                        </div>
                      </motion.div>
                      <div className="hero-left">
                        <div
                          className="circle"
                          style={{
                            backgroundImage: `url(${slider.node.background.file.url})`
                          }}
                        ></div>
                        <div className="grid">
                            <motion.img
                              initial={{ x: -50, opacity: 0 }}
                              animate={{ x: 0, opacity: 1 }}
                              transition={{ duration: 0.3 }}
                              src={slider.node.imageEn.file.url}
                              alt="devices"
                            />
                        </div>
                      </div>
                    </StyledHero>
                  </StyledSliderItem>
                );
              })}
            </Slider>
          </StyledCarousel>
        )}
      />
    );
  }
}

export default HeaderCarousel;
