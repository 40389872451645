import styled from 'styled-components';

export const AboutHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 90%;
  margin: 8rem auto;
  font-family: ${props => props.theme.fonts.cairo};
  .photo {
    width: 45%;
    img {
      clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
    }
  }
  video {
    width: 100%;
    height: 100%;
  }
  .header {
    width: 50%;
    text-align: right;
    direction: rtl;
    h1 {
      font-size: 5rem;
      color: ${props => props.theme.colors.red};
    }
    p {
      width: 90%;
      float: right;
      text-align: justify;
      font-size: 1.7rem;
      line-height: 3.5rem;
      color: ${props => props.theme.colors.grey2};
    }
  }
  @media (max-width: 1024px) {
    flex-direction: column;
    .photo {
      width: 90%;
      margin: auto;
    }
    .header {
      width: 100%;
      text-align: center;
      margin-top: 2rem;
      p {
        width: 100%;
      }
    }
  }

  @media (max-width: 414px) {
    .photo {
      height: 35rem;
    }
  }
`;
