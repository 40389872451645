import { motion } from 'framer-motion';
import styled from 'styled-components';

export const StyledHeader = styled(motion.header)`
  height: 20rem;
  width: 90%;
  margin: 10rem auto;
  background: ${props => props.theme.colors.red};
  position: relative;
  .gatsby-image-wrapper {
    height: 100%;
  }
  @media (max-width: 450px) {
    margin: 10rem auto 4rem;
  }
`;

export const StyledDetails = styled.div`
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: 1rem auto 10rem;
  font-family: ${props => props.theme.fonts.cairo};
  .block {
    width: 48%;
    .header {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid#b5b5b5;
      color: #000;
      img {
        width: 15%;
        margin-right: 1rem;
      }
      h2 {
        font-size: 2.4rem;
      }
    }
    .info {
      display: flex;
      flex-direction: row-reverse;
      justify-content: center;
      margin-top: 2rem;
      .info-right {
        width: 10%;
      }
      .info-left {
        width: 60%;
        h2 {
          margin-left: 2rem;
          font-size: 1.4rem;
          margin-bottom: 0;
          color: ${props => props.theme.colors.red};
        }
        p {
          margin-top: 1rem;
          margin-left: 2rem;
          font-size: 1.5rem;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .block {
      .header h2 {
        font-size: 1.6rem;
        text-align: right;
      }
      .info {
        align-items: center;
        .info-right {
          width: 15%;
          p {
            font-size: 1.2rem;
          }
        }
      }
    }
  }
  @media (max-width: 450px) {
    flex-direction: column-reverse;
    .block {
      width: 100%;
      margin-bottom: 6rem;
    }
  }
`;
