import { motion } from 'framer-motion';
import styled from 'styled-components';

export const StyledHero = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2rem auto;
  z-index: 5;
  .hero-left {
    width: 50%;
    position: relative;
    .circle {
      border-radius: 0 40rem 40rem 0;
      background: ${props => props.color || props.theme.colors.red};
      background-repeat: no-repeat;
      background-size: cover;
      width: 90%;
      height: 68rem;
    }
    .grid {
      position: absolute;
      left: 1rem;
      top: -2rem;
      img {
        height: 75rem;
        width: auto;
      }
    }
  }
  .content {
    font-family: ${props => props.theme.fonts.cairo};
    text-align: right;
    direction: rtl;
    display: flex;
    flex-direction: column;
    margin: 2rem 5rem;
    width: 50%;
    h1 {
      font-weight: 700;
      font-size: 3.6rem;
      color: ${props => props.color || props.theme.colors.red};
      margin-bottom: 3rem;
      .typed-cursor {
        margin-left: 1rem;
      }
    }
    .typo {
      min-height: 16rem;
    }
    p {
      color: #9b9b9b;
      float: right;
      margin: 0 0 1.5rem;
      line-height: 3.5rem;
      font-size: 1.6rem;
      text-align: justify;
      width: 80%;
    }
    a {
      color: ${props => props.color || props.theme.colors.red};
      border: 2px solid ${props => props.color || props.theme.colors.red};
      padding: 1.5rem 5rem;
      text-decoration: none;
      border-radius: 2.5rem;
      display: inline-block;
      margin-top: 1.5rem;
      font-size: 1.5rem;
      transition: 0.3s all;
      &:hover {
        background: ${props => props.color || props.theme.colors.red};
        color: #ffffff;
      }
    }
  }
  .carousel-buttons {
    button {
      display: inline-block;
      margin-top: 4rem;
      border: 0;
      cursor: pointer;
      outline: 0;
      span {
        font-size: 2.2rem;
      }
    }
    .next {
      background: ${props => props.color || props.theme.colors.red};
      padding: 1rem 2.8rem;
      color: #ffffff;
    }
    .prev {
      padding: 1rem 1.8rem;
      background: #333333;
      transition: 0.3s all;
      color: #ffffff;
      &:hover {
        padding: 1.2rem 2.2rem;
        background: #e4e4e4;
        color: #222222;
      }
    }
  }
  .partners-sec {
    width: 95%;
    margin-top: 5rem;
    h2 {
      font-size: 1.8rem;
      color: ${props => props.color || props.theme.colors.red};
      font-weight: bold;
    }
    .partners {
      width: 100%;
      display: flex;
      .partner {
        width: 20%;
        img {
          filter: grayscale(100%);
          &:hover {
            filter: none;
          }
        }
      }
    }
  }
  @media (min-height: 1080px) {
    .hero-left .circle {
      height: 77rem;
    }
  }
  @media (min-width: 1920px) {
    .hero-left .grid {
      left: 2rem;
      img {
        height: 80rem;
      }
    }
  }
  @media (max-width: 1024px) {
    .content {
      h1 {
        font-size: 3.1rem;
      }
      p {
        font-size: 1.3rem;
      }
    }
    .hero-left .grid {
      top: 0;
      img {
        height: 65rem;
      }
    }
  }
  @media (max-width: 768px) {
    margin: 10rem auto 0;
    .hero-left {
      .circle {
        height: 45rem;
      }
      .grid {
        left: 1rem;
        img {
          height: 42rem;
        }
      }
    }
    .content {
      margin: 2rem 3rem;
      h2 {
        font-size: 2rem;
      }
      p {
        font-size: 1.2rem;
        line-height: 2.2rem;
      }
      a {
        margin-top: 0;
        padding: 1rem 3rem;
      }
    }
    .carousel-buttons {
      .prev {
        padding: 0.8rem 1.4rem;
      }
      .next {
        padding: 0.8rem 2.6rem;
      }
    }
  }
  @media (max-width: 450px) {
    flex-direction: column;
    margin-top: 5rem;
    .hero-left {
      display: none;
    }
    .content {
      width: auto;
      margin-right: 3rem;
      p {
        width: 100%;
      }
    }
  }
  @media (max-width: 360px) {
    .content {
      margin: 0 3rem;
    }
    .partners-sec {
      .partners {
        width: 100%;
        flex-wrap: nowrap;
        overflow-x: auto;
        .partner {
          width: 50%;
          flex: 0 0 auto;
        }
      }
    }
  }
`;

export const StyledCategories = styled.div`
  margin: 10rem 0;
  font-family: ${props => props.theme.fonts.cairo};
  .categories {
    width: 90%;
    margin: 10rem auto 5rem;
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    justify-content: space-between;
    list-style: none;
    li {
      width: 32.5%;
    }
    .category {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      background: #f5f5f5;
      margin-top: 2rem;
      box-shadow: rgba(184, 184, 184, 0.3) 0px 5px 0px 0px;
      a {
        text-decoration: none;
        color: #000000;
      }
      &:hover {
        .gatsby-image-wrapper {
          &:before {
            opacity: 1;
          }
        }
        img {
          background: #ffffff;
          border-radius: 50%;
        }
        h2 {
          color: #ffffff;
        }
      }
      div {
        padding: 3rem 0;
      }
      img {
        width: 25%;
        margin: auto;
        transition: 0.5s all;
      }
      h2 {
        transition: 0.5s all;
      }
      .gatsby-image-wrapper {
        height: 100%;
        &:before {
          opacity: 0;
          transition: 0.3s all;
        }
      }
    }
  }
  @media (min-width: 1920px) {
    .categories .category {
      border: 2px solid#d6d6d6;
    }
  }
  @media (max-width: 768px) {
    .categories .category {
      width: 49%;
    }
  }
  @media (max-width: 450px) {
    margin: 5rem 0 10rem;
    .categories {
      flex-wrap: nowrap;
      flex-direction: row;
      overflow-x: auto;
      width: 100%;
      margin: 0 0 5rem;
      overflow-y: hidden;
      .category {
        width: 65%;
        border-right: 1px solid#d2d2d2;
        flex: 0 0 auto;
      }
    }
  }
`;

export const StyledKnowMore = styled.div`
  width: 90%;
  margin: 0 auto 10rem;
  font-family: ${props => props.theme.fonts.cairo};
  .heading {
    text-align: right;
    margin-bottom: 5rem;
    font-size: 1.5rem;
    span {
      display: block;
      color: ${props => props.theme.colors.red};
      font-size: 2.5rem;
      font-weight: bold;
    }
  }
  .service {
    display: flex;
    justify-content: space-between;
    .service-photo {
      width: 50%;
      video {
        width: 98%;
        border: 3px solid#e4e4e4;
        height: 100%;
      }
      img {
        height: 100%;
      }
    }
    .service-details {
      width: 45%;
      display: flex;
      align-items: center;
      .outline {
        height: 92%;
        width: 100%;
        border: 3px solid#e4e4e4;
        .content {
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          justify-content: center;
          .box {
            padding: 1.5rem 4rem;
            -webkit-transition: 0.5s all;
            transition: 0.5s all;
            background: #ffffff;
            width: 80%;
            &:nth-child(1) {
              border-top: 2px solid#eaeaea;
            }
            &:nth-child(2) {
              border-top: 2px solid#eaeaea;
              border-bottom: 2px solid#eaeaea;
            }
            &:nth-child(3) {
              border-bottom: 2px solid#eaeaea;
            }
            &:hover {
              padding: 3rem 4rem;
              background: ${props => props.theme.colors.red};
              .more-heading {
                img {
                  background: #ffffff;
                  border-radius: 50%;
                }
                h2 {
                  color: #ffffff;
                }
              }
              .more-content {
                color: #ffffff;
              }
            }
            .more-heading {
              display: flex;
              align-items: center;
              justify-content: flex-end;
              img {
                width: 10%;
                margin-left: 2rem;
              }
              h2 {
                font-size: 1.8rem;
                color: ${props => props.theme.colors.red};
              }
            }
            .more-content {
              font-size: 1.3rem;
              text-align: justify;
              direction: rtl;
              line-height: 2rem;
              color: ${props => props.theme.colors.grey};
            }
          }
        }
      }
    }
  }
  .has-video {
    .service-photo {
      width: 65%;
    }
    .service-details {
      width: 35%;
      .outline .content .box {
        padding: 1.5rem 3.5rem;
        &:hover {
          padding: 3rem 3.5rem;
        }
      }
    }
  }
  @media (min-width: 1600px) {
    .service {
      justify-content: flex-start;
      .service-details {
        width: 50%;
        .outline .content .box {
          padding: 1.5rem 5rem;
          &:hover {
            padding: 3rem 5rem;
          }
        }
      }
    }
  }
  @media (max-width: 1024px) {
    .service {
      flex-direction: column;
      .service-photo {
        img {
          width: 100%;
          height: auto;
        }
      }
      .service-photo,
      .service-details {
        width: 95%;
        margin: auto;
      }
    }
  }
  @media (max-width: 450px) {
    .service .service-details .outline .content .box {
      padding: 1.5rem 3rem;
      &:hover {
        padding: 3rem;
      }
    }
  }
`;
export const GeneralHeading = styled.div`
  text-align: center;
  font-family: ${props => props.theme.fonts.cairo};
  margin-bottom: 5rem;
  margin-top: ${props => (props.margin ? props.margin : '5rem')};
  span {
    display: block;
    font-size: 4rem;
    color: ${props => props.theme.colors.red};
    font-weight: 700;
  }
  @media (min-width: 1920px) {
    h2 {
      font-size: 2rem;
      span {
        font-size: 4rem;
      }
    }
  }
`;

export const StyledProjects = styled.div`
  margin: 8rem auto 4rem;
  width: 90%;
  font-family: ${props => props.theme.fonts.cairo};
  .projects {
    display: flex;
    justify-content: center;
    flex-direction: row-reverse;
    .project {
      width: 32.5%;
      margin: 0 0.5rem 3rem;
      text-align: right;
      a {
        text-decoration: none;
        color: #000000;
        &:hover {
          color: ${props => props.theme.colors.red};
        }
      }
      h3 {
        font-size: 1.6rem;
        margin-bottom: 0;
        margin-right: 0.5rem;
      }
      p {
        font-size: 1.3rem;
        color: #333333;
        margin-right: 0.5rem;
      }
      .gatsby-image-wrapper {
        height: 30rem;
      }
    }
  }
  .more {
    display: flex;
    justify-content: center;
    a {
      padding: 1.6rem 4rem;
      border-radius: 3rem;
      font-size: 1.5rem;
      border: 2px solid ${props => props.theme.colors.red};
      text-decoration: none;
      color: ${props => props.theme.colors.red};
      transition: 0.3s all;
      margin-bottom: 5rem;
      &:hover {
        color: white;
        background: ${props => props.theme.colors.red};
      }
    }
  }
  @media (min-width: 1920px) {
    .projects .project {
      .gatsby-image-wrapper {
        height: 35rem;
      }
    }
  }
  @media (max-width: 1024px) {
    .projects .project {
      margin: 0 0.2rem 3rem;
    }
  }
  @media (max-width: 768px) {
    .projects {
      flex-wrap: wrap;
      .project {
        width: 100%;
      }
    }
  }
`;

export const WhyUs = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin: 10rem auto;
  justify-content: space-between;
  width: 90%;
  align-items: flex-start;
  .why-box {
    width: 24%;
    border: 2px solid#d6d6d6;
    text-align: center;
    border-radius: 3rem 3rem 0 0;
    position: relative;
    font-family: ${props => props.theme.fonts.cairo};
    transition: 0.4s all;
    min-height: 18rem;
    &:hover {
      border: 2px solid ${props => props.theme.colors.red};
      button {
        background: ${props => props.theme.colors.red};
        border-color: ${props => props.theme.colors.red};
        span:before {
          color: #ffffff;
        }
      }
    }
    img {
      width: 25%;
      margin: -4rem auto 2rem;
      background: #fff;
    }
    h2 {
      color: ${props => props.theme.colors.red};
      font-weight: 700;
    }
    .content {
      height: 4rem;
      direction: rtl;
      overflow: hidden;
    }
    .why-box-open {
      height: auto;
    }
  }
  p {
    font-size: 1.3rem;
    line-height: 2rem;
    width: 85%;
    margin: auto;
    margin-bottom: 4rem;
  }
  button {
    position: absolute;
    bottom: -5rem;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 1rem;
    background: #fff;
    border: 1px solid#c1c1c1;
    border-radius: 50%;
    font-size: 2.5rem;
    cursor: pointer;
    outline: none;
  }
  @media (max-width: 1024px) {
    flex-wrap: wrap;
    .why-box {
      width: 48%;
      margin-bottom: 7rem;
    }
  }
  @media (max-width: 411px) {
    margin-bottom: 10rem;
    .why-box {
      width: 100%;
      margin-top: 3rem;
    }
  }
`;

export const StyledServices = styled(motion.div)`
  width: 90%;
  margin: 10rem auto;
  position: relative;
  font-family: ${props => props.theme.fonts.cairo};
  .service {
    display: flex;
    margin-bottom: 10rem;
    .service-photo {
      width: 60%;
      position: relative;
      a {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-decoration: none;
        background: #000000;
        color: white;
        font-size: 1.6rem;
        padding: 1.5rem 3rem;
        z-index: 4;
      }
      .gatsby-image-wrapper {
        opacity: 0.7;
      }
    }
    .service-typography {
      width: 40%;
      position: relative;
      .left {
        right: -15rem !important;
        left: 0 !important;
        z-index: 2;
      }
      .content {
        position: absolute;
        top: 5%;
        border: 2px solid#e0e0e0;
        background: #ffffff;
        min-height: 90%;
        width: calc(100% + 15rem);
        left: -15rem;
        text-align: right;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        h2 {
          padding: 0 5rem 2rem;
          font-size: 2.4rem;
          margin-bottom: 0;
        }
        p {
          margin: 0;
          padding: 0 5rem;
          font-size: 1.4rem;
          line-height: 3rem;
          color: ${props => props.theme.colors.grey2};
          direction: rtl;
        }
        .icons {
          display: flex;
          flex-wrap: wrap;
          list-style-type: none;
          margin: 2rem 5rem 1rem;
          width: 70%;
          li {
            width: 50%;
            display: flex;
            margin-bottom: 1rem;
            justify-content: flex-end;
            .gatsby-image-wrapper, img {
              width: 15%;
            }
            p {
              padding: 0;
              margin-right: 1rem;
            }
          }
        }
        a {
          padding: 1.6rem 3.2rem;
          font-size: 1.3rem;
          font-weight: 700;
          text-decoration: none;
          border-radius: 3rem;
          margin: 1rem 5rem 3rem;
          display: inline-block;
        }
      }
    }
  }
  @media (max-width: 1024px) {
    .service .service-typography .content .icons {
      display: none;
    }
  }
  @media (max-width: 768px) {
    .service {
      flex-direction: column;
      .service-photo {
        width: 100%;
      }
      .service-typography {
        width: 100%;
        .content {
          position: relative;
          width: 100%;
          left: 0;
          h2 {
            padding: 0 3rem 3rem;
          }
          p {
            padding: 0 3rem;
          }
          .icons {
            display: flex;
            margin: 2rem 3rem;
          }
          a {
            margin: 1rem 3rem 3rem;
          }
        }
      }
    }
    .left-sided {
      flex-direction: column-reverse;
    }
  }
  @media (max-width: 414px) {
    .service .service-typography .content .icons li {
      width: 100%;
    }
  }
`;

export const StyledOurPartners = styled(motion.div)`
  width: 80%;
  margin: 0 auto 10rem;
  img {
    filter: grayscale(100%);
    &:hover {
      filter: none;
    }
  }
`;
