import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import React, { useEffect, useState } from 'react';
import Categories from '../components/Categories';
import HeaderCarousel from '../components/HeaderCarousel';
import KnowMore from '../components/KnowMore';
import LatestPosts from '../components/LatestPosts';
import Layout from '../components/layout';
import OurPartners from '../components/OurPartners';
import SEO from '../components/seo';
import SocialFeeds from '../components/SocialFeeds';
import WhyUs from '../components/WhyUs';
import { Link } from '../components/LinkWithBack';
import {
  GeneralHeading,
  StyledCategories,
  StyledProjects
} from '../components/styles';

const IndexPage = ({ data, location }) => {
  const [currentURL, setCurrentURL] = useState('');

  useEffect(() => {
    if (typeof window == 'undefined') {
      return setCurrentURL(location.pathname);
    }
  }, [location.pathname]);

  return (
    <Layout>
      <SEO title="Home" />
      <HeaderCarousel featuredPartners={data.featuredPartners.edges} />
      <KnowMore />
      <WhyUs headings={data.contentfulAboutUs} data={data} />
      <GeneralHeading>
        <h2>
          {data.contentfulPreviousWorkCategories.categoriesHeadingEn}{' '}
          <span>
            {data.contentfulPreviousWorkCategories.categoriesHeadingBottomEn}
          </span>
        </h2>
      </GeneralHeading>
      <StyledCategories>
        <Categories />
      </StyledCategories>
      <GeneralHeading>
        <h2>
          {data.contentfulPreviousWorkCategories.projectsHeadingEn}{' '}
          <span>
            {data.contentfulPreviousWorkCategories.projectsHeadingBottomEn}
          </span>
        </h2>
      </GeneralHeading>
      <StyledProjects>
        <div className="projects">
          {data.allProjects.edges.map(project => (
            <div key={project.node.englishTitle} className="project">
              <a
                href={project.node.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Img fluid={project.node.hero.fluid} />
              </a>
              <a
                href={project.node.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <h3>{project.node.englishTitle}</h3>
              </a>
              <p>{project.node.category.englishName}</p>
            </div>
          ))}
        </div>
        <div className="more">
          <Link state={currentURL} to="/projects">
            More Projects
          </Link>
        </div>
      </StyledProjects>
      <GeneralHeading>
        <h2>
          {data.contentfulBlog.blogHeadingHomepageEn}{' '}
          <span>{data.contentfulBlog.blogHeadingHomepageBottomEn}</span>
        </h2>
      </GeneralHeading>
      <LatestPosts allPosts={data.allPosts.edges} />
      <SocialFeeds data={data.contentfulSocialMediaInfo} />
      <OurPartners
        headings={data.contentfulOurPartners}
        dataHomepage={data.HomepageContent}
      />
    </Layout>
  );
};

export const data = graphql`
  {
    HomepageContent: contentfulHomepage(name: { eq: "Homepage" }) {
      whyUsHeading
      whyUsHeadingBottom
    }
    contentfulAboutUs {
      whyUsBoxOneIcon {
        file {
          url
        }
      }
      whyUsBoxTwoIcon {
        file {
          url
        }
      }
      whyUsBoxThreeIcon {
        file {
          url
        }
      }
      whyUsBoxFourIcon {
        file {
          url
        }
      }
      whyUsHeadingOneEn
      whyUsHeadingTwoEn
      whyUsHeadingThreeEn
      whyUsHeadingFourEn
      whyUsBoxOneEn {
        json
      }
      whyUsBoxTwoEn {
        json
      }
      whyUsBoxThreeEn {
        json
      }
      whyUsBoxFourEn {
        json
      }
    }
    contentfulAboutUs {
      whyUsHeading
      whyUsHeadingEn
      whyUsHeadingBottom
      whyUsHeadingBottomEn
    }
    contentfulPreviousWorkCategories {
      categoriesHeadingEn
      categoriesHeadingBottomEn
      projectsHeadingEn
      projectsHeadingBottomEn
    }
    contentfulBlog {
      blogHeadingHomepageEn
      blogHeadingHomepageBottomEn
    }
    contentfulSocialMediaInfo {
      socialMediaHeadingEn
      socialMediaHeadingBottomEn
      facebookLink
      twitterLink
      instagramPostURL
    }
    contentfulOurPartners {
      homepagePartnersEn
      homepagePartnersBottomEn
    }
    allProjects: allContentfulProjects(
      limit: 3
      sort: { fields: [updatedAt], order: DESC }
    ) {
      edges {
        node {
          englishTitle
          category {
            englishName
          }
          hero {
            fluid(maxWidth: 1920) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          url
          slug
        }
      }
    }
    allPosts: allContentfulPosts(
      limit: 6
      sort: { fields: [updatedAt], order: DESC }
    ) {
      edges {
        node {
          englishTitle
          slug
          hero {
            fluid(maxWidth: 600) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
      }
    }
    featuredPartners: allContentfulPartners(
      limit: 5
      filter: { featured: { eq: true } }
      sort: { fields: [updatedAt], order: DESC }
    ) {
      edges {
        node {
          partnerName
          logo {
            fluid(maxWidth: 250) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`;
export default IndexPage;
