import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import styled from 'styled-components';
import Layout from '../../components/ar/layout';
import SEO from '../../components/ar/seo';

const PaymentMethods = ({ data }) => {
  const StyledPaymentMethods = styled.div`
    font-family: ${props => props.theme.fonts.cairo};
    text-align: right;
    margin-bottom: 10rem;
    .hero {
      height: 20vh;
      .gatsby-image-wrapper {
        height: 100%;
      }
    }
    .content {
      width: 75%;
      padding: 2rem 0;
      margin: 5rem auto 0;
      p {
        font-size: 1.5rem;
        line-height: 4rem;
        color: #333333;
      }
      .payment-methods {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 6rem;
        .method {
          width: 31%;
          direction: rtl;
          margin-bottom: 3rem;
          .gatsby-image-wrapper {
            border: 1px solid#d6d6d6;
            box-shadow: 5px 5px 10px #d6d6d6;
            height: 20rem;
          }
        }
      }
    }
    .title {
      margin-top: 3rem;
      z-index: 2;
      position: relative;
      font-size: 2.5rem;
      color: ${props => props.theme.colors.red};
    }
    .date {
      font-size: 1.8rem;
      display: inline-block;
      margin-bottom: 3rem;
    }
    @media (max-width: 768px) {
      .content .payment-methods .method {
        width: 49%;
      }
    }
    @media (max-width: 450px) {
      .content .payment-methods .method {
        width: 100%;
      }
    }
  `;
  return (
    <Layout>
      <SEO title="وسائل دفعنا" />
      <StyledPaymentMethods>
        <div className="hero">
          <Img
            fluid={data.contentfulPaymentMethods.paymentMethodsImage.fluid}
          />
        </div>
        <div className="content">
          <h1 className="title">وسائل الدفع</h1>
          <div className="payment-methods">
            <div className="method">
              <Img
                fluid={data.contentfulPaymentMethods.paymentMethodOneImg.fluid}
              />
              {documentToReactComponents(
                data.contentfulPaymentMethods.paymentMethodOneContent.json
              )}
            </div>
            <div className="method">
              <Img
                fluid={data.contentfulPaymentMethods.paymentMethodTwoImg.fluid}
              />
              {documentToReactComponents(
                data.contentfulPaymentMethods.paymentMethodTwoContent.json
              )}
            </div>
            <div className="method">
              <Img
                fluid={
                  data.contentfulPaymentMethods.paymentMethodThreeImg.fluid
                }
              />
              {documentToReactComponents(
                data.contentfulPaymentMethods.paymentMethodThreeContent.json
              )}
            </div>
            <div className="method">
              <Img
                fluid={data.contentfulPaymentMethods.paymentMethodFourImg.fluid}
              />
              {documentToReactComponents(
                data.contentfulPaymentMethods.paymentMethodFourContent.json
              )}
            </div>
            <div className="method">
              <Img
                fluid={data.contentfulPaymentMethods.paymentMethodFiveImg.fluid}
              />
              {documentToReactComponents(
                data.contentfulPaymentMethods.paymentMethodFiveContent.json
              )}
            </div>
            <div className="method">
              <Img
                fluid={data.contentfulPaymentMethods.paymentMethodSixImg.fluid}
              />
              {documentToReactComponents(
                data.contentfulPaymentMethods.paymentMethodSixContent.json
              )}
            </div>
          </div>
        </div>
      </StyledPaymentMethods>
    </Layout>
  );
};

export const data = graphql`
  {
    contentfulPaymentMethods {
      paymentMethodOne
      paymentMethodOneImg {
        fluid(maxWidth: 600) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      paymentMethodOneContent {
        json
      }
      paymentMethodTwo
      paymentMethodTwoImg {
        fluid(maxWidth: 600) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      paymentMethodTwoContent {
        json
      }
      paymentMethodThree
      paymentMethodThreeImg {
        fluid(maxWidth: 600) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      paymentMethodThreeContent {
        json
      }
      paymentMethodFour
      paymentMethodFourImg {
        fluid(maxWidth: 600) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      paymentMethodFourContent {
        json
      }
      paymentMethodFive
      paymentMethodFiveImg {
        fluid(maxWidth: 600) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      paymentMethodFiveContent {
        json
      }
      paymentMethodSix
      paymentMethodSixImg {
        fluid(maxWidth: 600) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      paymentMethodSixContent {
        json
      }
    }
    contentfulPaymentMethods {
      paymentMethodsImage {
        fluid(maxWidth: 2560) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
  }
`;

export default PaymentMethods;
