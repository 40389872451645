import React from 'react';
import styled from 'styled-components';

const GoUp = () => {
  const StyledGoUp = styled.a`
    position: fixed;
    bottom: 12.5rem;
    right: 2.6rem;
    padding: 1rem;
    border-radius: 50%;
    text-decoration: none;
    font-size: 4rem;
    color: #ffffff;
    display: flex;
    z-index: 5;
    background: ${props => props.theme.colors.red};
    transition: 0.3s background;
    &:hover {
      background: #222222;
    }
  `;

  let hrefLink = '#';

  return (
    <StyledGoUp href={hrefLink}>
      <span className="icon-chevrons-up"></span>
    </StyledGoUp>
  );
};

export default GoUp;
