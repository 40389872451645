import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { motion } from 'framer-motion';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { Link } from '../components/LinkWithBack';
import {
  ControlButtons,
  ProjectsFilters,
  ProjectsHeader,
  StyledProjects
} from '../components/styles/projects';
import ArrowBackIcon from '../images/icons/arrow-back.svg';
import GoHomeIcon from '../images/icons/go-home.svg';

const Category = ({ data, location }) => {
  const [currentURL, setCurrentURL] = useState('');

  useEffect(() => {
    if (typeof window == 'undefined') {
      return setCurrentURL(location.pathname);
    }
  }, [location.pathname]);

  const renderBack = () => {
    if (typeof window !== 'undefined') {
      if (location.state && location.state.prevUrl) {
        return (
          <Link
            state={currentURL}
            to={`${location.state.prevUrl}#${location.state.id}`}
          >
            <img src={ArrowBackIcon} alt="go back" />
          </Link>
        );
      }
    }
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const renderPictures = () => {
    if (
      data.categoryDetails.sliderImages &&
      data.categoryDetails.sliderImages.length > 0
    ) {
      return (
        <Slider {...settings}>
          {data.categoryDetails.sliderImages.map((slide, i) => (
            <Img key={i} fluid={slide.fluid} />
          ))}
        </Slider>
      );
    }
  };
  return (
    <Layout>
      <SEO title={data.categoryDetails.englishName} />
      <ProjectsHeader>
        <motion.div
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.5 }}
          className="photo"
        >
          <Img fluid={data.categoryDetails.largeBackground.fluid} />
        </motion.div>
        <div className="header-content">
          <h1>{data.categoryDetails.englishName}</h1>
        </div>
      </ProjectsHeader>
      <ControlButtons>
        {renderBack()}
        <Link state={currentURL} to="/">
          <img src={GoHomeIcon} alt="go home" />
        </Link>
      </ControlButtons>
      <StyledProjects>
        <div className="from-contentful">
          <div className="content-from-contentful">
            {documentToReactComponents(
              data.categoryDetails.englishContent.json
            )}
          </div>
          <div className="slider-images">{renderPictures()}</div>
          <div className="go-to-behance">
            <Link state={currentURL} to="/projects">
              <span className="icon-chevron-left">
                <span className="path1"></span>
                <span className="path2"></span>
              </span>
              Visit our Projects
            </Link>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={data.contentfulSocialMediaInfo.behanceLink}
            >
              <span className="icon-behance">
                <span className="path1"></span>
                <span className="path2"></span>
              </span>
              Visit our Behance
            </a>
          </div>
        </div>
        <ProjectsFilters>
          <li>
            <Link
              state={currentURL}
              to="/projects"
              activeClassName="current-type"
            >
              All Categories
            </Link>
          </li>
          <li>
            <Link
              activeClassName="current-type"
              to={`/category/${data.categoryDetails.slug}`}
            >
              {data.categoryDetails.englishName}
            </Link>
          </li>
        </ProjectsFilters>
      </StyledProjects>
    </Layout>
  );
};

export const data = graphql`
  query($slug: String!) {
    categoryDetails: contentfulCategories(slug: { eq: $slug }) {
      englishName
      slug
      largeBackground {
        fluid(maxWidth: 1920) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      englishContent {
        json
      }
      sliderImages {
        fluid(maxWidth: 800) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
    contentfulSocialMediaInfo {
      behanceLink
    }
  }
`;

export default Category;
