import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import Img from 'gatsby-image';
import React from 'react';
import Layout from '../../components/ar/layout';
import { Link } from '../../components/ar/LinkWithBack';
import SEO from '../../components/ar/seo';
import {
  GeneralHeading,
  StyledCategories,
  StyledServices
} from '../../components/styles';

const Services = ({ data }) => {
  const featuredCategoryOne =
    data.contentfulFeaturedCategories.categoryOne.name;
  const featuredCategoryTwo =
    data.contentfulFeaturedCategories.categoryTwo.name;
  const featuredCategoryThree =
    data.contentfulFeaturedCategories.categoryThree.name;

  const renderCategory = category => {
    if (
      category.node.name !== featuredCategoryOne &&
      category.node.name !== featuredCategoryTwo &&
      category.node.name !== featuredCategoryThree
    ) {
      return (
        <li
          id={category.node.slug}
          key={category.node.slug}
          className="category"
        >
          <Link
            state={{ id: category.node.slug }}
            to={`/ar/category/${category.node.slug}`}
          >
            <BackgroundImage
              Tag="div"
              fluid={category.node.background.fluid}
              backgroundColor={`#f5f5f5`}
            >
              <img src={category.node.icon.file.url} alt={category.node.name} />
              <h2>{category.node.name}</h2>
            </BackgroundImage>
          </Link>
        </li>
      );
    }
  };

  return (
    <Layout>
      <SEO title="خدماتنا" />
      <GeneralHeading>
        <h2>
          اعرف جميع الخدمات التي تقدمها <span>ثري بوكس هوست</span>
        </h2>
      </GeneralHeading>
      <StyledServices
        initial={{
          y: -50,
          opacity: 0
        }}
        animate={{
          y: 0,
          opacity: 1
        }}
        transition={{ duration: 0.5 }}
      >
        <div
          className="service"
          id={data.contentfulFeaturedCategories.categoryOne.slug}
        >
          <div
            style={{
              background: `${data.contentfulFeaturedCategories.categoryOneColor}`
            }}
            className="service-photo"
          >
            <Img fluid={data.contentfulFeaturedCategories.photoOne.fluid} />
          </div>
          <div className="service-typography">
            <div className="content">
              <h2
                style={{
                  color: `${data.contentfulFeaturedCategories.categoryOneColor}`
                }}
              >
                {data.contentfulFeaturedCategories.categoryOne.name}
              </h2>
              {documentToReactComponents(
                data.contentfulFeaturedCategories.contentOne.json
              )}
              <ul className="icons">
                <li>
                  <p>
                    {
                      data.contentfulFeaturedCategories.iconOneCategoryOne
                        .description
                    }{' '}
                  </p>
                  <img
                    src={
                      data.contentfulFeaturedCategories.iconOneCategoryOne.file
                        .url
                    }
                    alt="icon"
                  />
                </li>
                <li>
                  <p>
                    {
                      data.contentfulFeaturedCategories.iconTwoCategoryOne
                        .description
                    }{' '}
                  </p>
                  <img
                    src={
                      data.contentfulFeaturedCategories.iconTwoCategoryOne.file
                        .url
                    }
                    alt="icon"
                  />
                </li>
                <li>
                  <p>
                    {
                      data.contentfulFeaturedCategories.iconThreeCategoryOne
                        .description
                    }{' '}
                  </p>
                  <img
                    src={
                      data.contentfulFeaturedCategories.iconThreeCategoryOne
                        .file.url
                    }
                    alt="icon"
                  />
                </li>
                <li>
                  <p>
                    {
                      data.contentfulFeaturedCategories.iconFourCategoryOne
                        .description
                    }{' '}
                  </p>
                  <img
                    src={
                      data.contentfulFeaturedCategories.iconFourCategoryOne.file
                        .url
                    }
                    alt="icon"
                  />
                </li>
              </ul>
              <div>
                <Link
                  state={{
                    id: data.contentfulFeaturedCategories.categoryOne.slug
                  }}
                  style={{
                    color: `${data.contentfulFeaturedCategories.categoryOneColor}`,
                    border: `2px solid${data.contentfulFeaturedCategories.categoryOneColor}`
                  }}
                  to={`/ar/category/${data.contentfulFeaturedCategories.categoryOne.slug}`}
                >
                  اقرا المزيد
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div
          className="service  left-sided"
          id={data.contentfulFeaturedCategories.categoryTwo.slug}
        >
          <div className="service-typography">
            <div className="content left">
              <h2
                style={{
                  color: `${data.contentfulFeaturedCategories.categoryTwoColor}`
                }}
              >
                {data.contentfulFeaturedCategories.categoryTwo.name}
              </h2>
              {documentToReactComponents(
                data.contentfulFeaturedCategories.contentTwo.json
              )}
              <ul className="icons">
                <li>
                  <p>
                    {
                      data.contentfulFeaturedCategories.iconOneCategoryTwo
                        .description
                    }{' '}
                  </p>
                  <img
                    src={
                      data.contentfulFeaturedCategories.iconOneCategoryTwo.file
                        .url
                    }
                    alt="icon"
                  />
                </li>
                <li>
                  <p>
                    {
                      data.contentfulFeaturedCategories.iconTwoCategoryTwo
                        .description
                    }{' '}
                  </p>
                  <img
                    src={
                      data.contentfulFeaturedCategories.iconTwoCategoryTwo.file
                        .url
                    }
                    alt="icon"
                  />
                </li>
                <li>
                  <p>
                    {
                      data.contentfulFeaturedCategories.iconThreeCategoryTwo
                        .description
                    }{' '}
                  </p>
                  <img
                    src={
                      data.contentfulFeaturedCategories.iconThreeCategoryTwo
                        .file.url
                    }
                    alt="icon"
                  />
                </li>
                <li>
                  <p>
                    {
                      data.contentfulFeaturedCategories.iconFourCategoryTwo
                        .description
                    }{' '}
                  </p>
                  <img
                    src={
                      data.contentfulFeaturedCategories.iconFourCategoryTwo.file
                        .url
                    }
                    alt="icon"
                  />
                </li>
              </ul>
              <div>
                <Link
                  state={{
                    id: data.contentfulFeaturedCategories.categoryTwo.slug
                  }}
                  style={{
                    color: `${data.contentfulFeaturedCategories.categoryTwoColor}`,
                    border: `2px solid${data.contentfulFeaturedCategories.categoryTwoColor}`
                  }}
                  to={`/ar/category/${data.contentfulFeaturedCategories.categoryTwo.slug}`}
                >
                  اقرا المزيد
                </Link>
              </div>
            </div>
          </div>
          <div
            style={{
              background: `${data.contentfulFeaturedCategories.categoryTwoColor}`
            }}
            className="service-photo"
          >
            <Img fluid={data.contentfulFeaturedCategories.photoTwo.fluid} />
          </div>
        </div>
        <div
          className="service"
          id={data.contentfulFeaturedCategories.categoryThree.slug}
        >
          <div
            style={{
              background: `${data.contentfulFeaturedCategories.categoryThreeColor}`
            }}
            className="service-photo"
          >
            <Img fluid={data.contentfulFeaturedCategories.photoThree.fluid} />
          </div>
          <div className="service-typography">
            <div className="content">
              <h2
                style={{
                  color: `${data.contentfulFeaturedCategories.categoryThreeColor}`
                }}
              >
                {data.contentfulFeaturedCategories.categoryThree.name}
              </h2>
              {documentToReactComponents(
                data.contentfulFeaturedCategories.contentOne.json
              )}
              <ul className="icons">
                <li>
                  <p>
                    {
                      data.contentfulFeaturedCategories.iconOneCategoryThree
                        .description
                    }{' '}
                  </p>
                  <img
                    src={
                      data.contentfulFeaturedCategories.iconOneCategoryThree
                        .file.url
                    }
                    alt="icon"
                  />
                </li>
                <li>
                  <p>
                    {
                      data.contentfulFeaturedCategories.iconTwoCategoryThree
                        .description
                    }{' '}
                  </p>
                  <img
                    src={
                      data.contentfulFeaturedCategories.iconTwoCategoryThree
                        .file.url
                    }
                    alt="icon"
                  />
                </li>
                <li>
                  <p>
                    {
                      data.contentfulFeaturedCategories.iconThreeCategoryThree
                        .description
                    }{' '}
                  </p>
                  <img
                    src={
                      data.contentfulFeaturedCategories.iconThreeCategoryThree
                        .file.url
                    }
                    alt="icon"
                  />
                </li>
                <li>
                  <p>
                    {
                      data.contentfulFeaturedCategories.iconFourCategoryThree
                        .description
                    }{' '}
                  </p>
                  <img
                    src={
                      data.contentfulFeaturedCategories.iconFourCategoryThree
                        .file.url
                    }
                    alt="icon"
                  />
                </li>
              </ul>
              <div>
                <Link
                  state={{
                    id: data.contentfulFeaturedCategories.categoryThree.slug
                  }}
                  style={{
                    color: `${data.contentfulFeaturedCategories.categoryThreeColor}`,
                    border: `2px solid${data.contentfulFeaturedCategories.categoryThreeColor}`
                  }}
                  to={`/ar/category/${data.contentfulFeaturedCategories.categoryThree.slug}`}
                >
                  اقرا المزيد
                </Link>
              </div>
            </div>
          </div>
        </div>
      </StyledServices>
      <StyledCategories>
        <ul className="categories">
          {data.allContentfulCategories.edges.map(category =>
            renderCategory(category)
          )}
        </ul>
      </StyledCategories>
    </Layout>
  );
};

export const data = graphql`
  {
    contentfulFeaturedCategories {
      categoryOne {
        name
        slug
      }
      categoryOneColor
      categoryTwoColor
      categoryThreeColor
      photoOne {
        fluid(maxWidth: 1500) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      contentOne {
        json
      }
      iconOneCategoryOne {
        file {
          url
        }
        description
      }
      iconTwoCategoryOne {
        file {
          url
        }
        description
      }
      iconThreeCategoryOne {
        file {
          url
        }
        description
      }
      iconFourCategoryOne {
        file {
          url
        }
        description
      }
      categoryTwo {
        name
        slug
      }
      photoTwo {
        fluid(maxWidth: 1500) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      contentTwo {
        json
      }
      iconOneCategoryTwo {
        file {
          url
        }
        description
      }
      iconTwoCategoryTwo {
        file {
          url
        }
        description
      }
      iconThreeCategoryTwo {
        file {
          url
        }
        description
      }
      iconFourCategoryTwo {
        file {
          url
        }
        description
      }
      categoryThree {
        name
        slug
      }
      photoThree {
        fluid(maxWidth: 1500) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      contentThree {
        json
      }
      iconOneCategoryThree {
        file {
          url
        }
        description
      }
      iconTwoCategoryThree {
        file {
          url
        }
        description
      }
      iconThreeCategoryThree {
        file {
          url
        }
        description
      }
      iconFourCategoryThree {
        file {
          url
        }
        description
      }
    }
    allContentfulCategories(sort: { fields: createdAt, order: DESC }) {
      edges {
        node {
          name
          slug
          icon {
            file {
              url
            }
          }
          background {
            fluid(maxWidth: 1920) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`;

export default Services;
