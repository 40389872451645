import { motion } from 'framer-motion';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import React, { useEffect, useState } from 'react';
import Layout from '../components/ar/layout';
import { Link } from '../components/ar/LinkWithBack';
import SEO from '../components/ar/seo';
import {
  ControlButtons,
  ProjectsFilters,
  ProjectsHeader,
  StyledProjects
} from '../components/styles/ar/projects';
import ArrowBackIcon from '../images/icons/arrow-back.svg';
import GoHomeIcon from '../images/icons/go-home.svg';

const Projects = ({ data, location }) => {
  const [currentURL, setCurrentURL] = useState('');

  useEffect(() => {
    if (typeof window !== 'undefined') {
      return setCurrentURL(location.pathname);
    }
  }, [location.pathname]);

  const renderBack = () => {
    if (typeof window !== 'undefined') {
      if (location.state && location.state.prevUrl) {
        return (
          <Link state={currentURL} to={location.state.prevUrl}>
            <img src={ArrowBackIcon} alt="go back" />
          </Link>
        );
      }
    }
  };

  return (
    <Layout>
      <SEO title={data.categoryDetails.name} />
      <ProjectsHeader>
        <motion.div
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.5 }}
          className="photo"
        >
          <Img fluid={data.categoryDetails.largeBackground.fluid} />
        </motion.div>
        <div className="header-content">
          <h1>{data.categoryDetails.name}</h1>
        </div>
      </ProjectsHeader>
      <ControlButtons>
        {renderBack()}
        <Link state={currentURL} to="/">
          <img src={GoHomeIcon} alt="go home" />
        </Link>
      </ControlButtons>
      <StyledProjects>
        <div className="left">
          <div className="projects">
            {data.allProjects.edges.map(project => (
              <div key={project.node.title} className="project">
                <a
                  href={project.node.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Img fluid={project.node.hero.fluid} />
                </a>
                <a
                  href={project.node.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <h3>{project.node.title}</h3>
                </a>
                <p>{project.node.category.name}</p>
              </div>
            ))}
          </div>
          <div className="go-to-behance">
            <Link
              state={currentURL}
              to={`/category/${data.categoryDetails.slug}`}
            >
              <span className="icon-chevron-left">
                <span className="path1"></span>
                <span className="path2"></span>
              </span>
              زيارة الخدمة
            </Link>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={data.contentfulSocialMediaInfo.behanceLink}
            >
              <span className="icon-behance">
                <span className="path1"></span>
                <span className="path2"></span>
              </span>
              البيهانس الخاص بنا
            </a>
          </div>
        </div>
        <ProjectsFilters>
          <li>
            <Link state={currentURL} to="/projects">
              الكل
            </Link>
          </li>
          <li>
            <Link
              state={currentURL}
              activeClassName="current-type"
              to={`/projects/${data.categoryDetails.slug}`}
            >
              {data.categoryDetails.name}
            </Link>
          </li>
        </ProjectsFilters>
      </StyledProjects>
    </Layout>
  );
};

export const data = graphql`
  query($slug: String!) {
    categoryDetails: contentfulCategories(slug: { eq: $slug }) {
      name
      slug
      largeBackground {
        fluid(maxWidth: 1920) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
    allProjects: allContentfulProjects(
      filter: { category: { slug: { eq: $slug } } }
      sort: { fields: createdAt, order: DESC }
    ) {
      edges {
        node {
          title
          category {
            name
          }
          hero {
            fluid(maxWidth: 1920) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          url
          slug
        }
      }
    }
    contentfulSocialMediaInfo {
      behanceLink
    }
  }
`;

export default Projects;
